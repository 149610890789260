import React from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useNavigate,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// STORES
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	userLogin,
	userNeedsAuth,
	userSetOtherUsers,
} from '@stores/_slices/user';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// LAYOUTS
import Page from '@layouts/Pages/Page';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Form, {
	ResponseParsedProps,
} from '@components/form';
import Link from '@components/link';
import StatusBanner from '@components/status-banner';

// MODULES
import dateUtils from '@modules/dateUtils';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// STYLES
import styles from './step-login.module.scss';
import InputText from '@components/form/input-text';
import {
	EnumInputType,
} from '@enums/form.enum';
import InputPassword from '@components/form/input-password';
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import Button from '@components/button';

function StepLogin() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Dispatches an action. This is the only way to trigger a state change.
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);

	const handleOnError = (response: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		switch (response.statusText) {
			case 'connection_limit_exceeded': {
				dispatch(userSetOtherUsers(response.payload.users));
				navigate(PATHS.ERROR.CONNECTION_LIMIT_EXCEEDED);
				break;
			}
			case 'error_auth_sms_sent_limit_exceeded': {
				// Manage error message
				dispatch(addStatusMsg({
					message: t('status.error_auth_sms_sent_limit_exceeded'),
					theme: EnumStatusTheme.ERROR,
				}));
				break;
			}
			case 'error_auth_ip':
				// Navigate to the unknown IP page
				navigate(PATHS.LOGIN.ERROR_AUTH_IP);
				break;
			default:
				// Manage error message
				if (response.statusText) {
					dispatch(addStatusMsg({
						theme: EnumStatusTheme.ERROR,
						message: t([
							`status.${response.statusText}`,
							t('status.default')
						])
					}));
				}
		}
	};

	const handleOnSuccess = (responseParsed: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		switch (responseParsed.statusText) {
			case 'success_step_2fa_sms': {
				dispatch(userNeedsAuth('success_step_2fa_sms'));
				navigate(PATHS.LOGIN.SUCCESS_STEP_2FA_SMS);
				break;
			}
			default:
				// We store user infos in Redux store
				dispatch(userLogin(responseParsed.payload.user));
		}
	};

	const showInfoLoginBanner = dateUtils.isBetweenTwoDates(new Date('2023-06-27 08:00:00'), new Date('2023-09-30 23:59:59'));

	return (
		<Page
			className={`${styles.step} wrapper__medium`}
			pageName='login'
		>
			<div
				className={styles.statusMsgWrapper}
				data-status-msg-wrapper
			>
				{showInfoLoginBanner ? (
					<StatusBanner
						message={t('status.info_login_user')}
						theme={EnumStatusTheme.INFO}
					/>
				) : null}
				{statusMsgs.length ? (
					statusMsgs.map((statusBanner, index) => {
						return (
							<StatusBanner
								key={index}
								message={statusBanner.message}
								theme={statusBanner.theme}
							/>
						);
					})
				) : null}
			</div>
			<div className={`wrapper__small ${styles.main}`}>
				<Form
					action={FETCHES.public.authentification.login}
					noValidate={true}
					showDevTool={true}
					onError={(responseParsed) => {
						handleOnError(responseParsed as ResponseParsedProps);
					}}
					onSuccess={(responseParsed) => {
						handleOnSuccess(responseParsed as ResponseParsedProps);
					}}
				>
					<InputText
						autoComplete='username'
						autoFocus={true}
						label={t('general.form.input.type.username.label')}
						name="login_form[_email]"
						placeholder={t('general.form.input.type.username.placeholder')}
						required={true}
						type={EnumInputType.EMAIL}
					/>
					<InputPassword
						autoComplete='current-password'
						label={t('format.capitalize', {
							text: t('general.form.input.type.password.label')
						})}
						name="login_form[_password]"
						placeholder={t('general.form.input.type.password.placeholder')}
						required={true}
						type={EnumInputType.PASSWORD}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.login')
						})}
						name="submit"
						type={EnumButtonType.SUBMIT}
					/>
				</Form>
				<Link
					href={PATHS.LOGIN.PASSWORD_FORGOT}
					theme={EnumThemeLink.PRIMARY}
					onClick={() => {
						dispatch(clearToastMsgs());
						dispatch(clearStatusMsgs());
					}}
				>
					{t('page.login.link_pwd')}
				</Link>
			</div>
		</Page>
	);
}

export default withTranslation()(StepLogin);
