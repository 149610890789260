import * as React from 'react';
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useDispatch, useSelector,
} from 'react-redux';
import {
	demandOffersUpdate,
} from '@stores/_slices/demands';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import FETCHES from '@routes/fetches';

// MODULES
import imagesModules from '@modules/images';
import utils from '@modules/utils';

// TYPES
import {
	OfferJson,
} from '@@types/Offer';
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';
import Button, {
} from '@components/button';

// STYLES
import styles from './ModalVisitExpired.module.scss';

interface ModalVisitExpiredProps {
	address?: string;
	date?: string;
	demandId?: number;
	offerId?: number;
	image?: string;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setModalCanceled?: Dispatch<SetStateAction<{
		enabled: boolean;
		action?: string;
	}>>;
	time?: string;
}

function ModalVisitExpired({
	address,
	date,
	demandId,
	image,
	offerId,
	setIsOpenModal,
	setModalCanceled,
	time,
}: ModalVisitExpiredProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const demandWithOffersGlobalState = useSelector((state: ReducerInstance) => state.demands.instance).collection.filter((item: DemandJsonPrivate) => demandId == item?.id) as DemandJsonPrivate[];
	const demandGlobalState = demandWithOffersGlobalState && demandWithOffersGlobalState[0] ? demandWithOffersGlobalState[0] : null;

	const dataAddress = {
		title: address,
		image_url: imagesModules.updateImageUrls(image, EnumImageFormatQuery.SQUARE) as string || undefined,
		descriptions: [
			{
				value: t('format.capitalize', {
					text: t('modals.visit.visit_date', {
						date: date,
						time: time
					})
				}),
				type: 'string',
				required: true,
			},
		],
	};

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setModalCanceled({
			enabled: true,
		});
	});
	const handleOnClickConfirm = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		const hiddenLabel = [
			'visit_expired',
			'visit_visited',
		];
		const bodyRequest = {
			announcement_contact_id: (demandGlobalState.offers.collection.filter((offer: OfferJson) => offer.id === offerId)[0] as OfferJson).visit.contact_id,
			action: 'visited',
		};
		const updatedOffers: OfferJson[] = [
		];
		fetch(utils.getURL(FETCHES.private.demands.offer.visit, {
			':demandid': `${demandId}`,
			':offerid': `${offerId}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify(bodyRequest),
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			setIsOpenModal(false);
			switch (responseParsed.status) {
				case 200:
					demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
						if (offer.id === offerId) {
							const offerUpdated = {
								...offer,
								states: [
									'visit_visited',
									...offer.states.filter(state => !hiddenLabel.includes(state)),
								],
								visit: {
									...offer.visit,
									state: bodyRequest.action,
								}
							};
							updatedOffers.push(offerUpdated);
						} else {
							updatedOffers.push(offer);
						}
					});
					dispatch(demandOffersUpdate({
						demand_id: demandId,
						offers: {
							...demandGlobalState.offers,
							collection: [
								...updatedOffers
							]
						}
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default',
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(err => {
			console.warn('ERROR : ', err);
			setIsOpenModal(false);
		});
	});

	return (
		<div className={`${styles.visit_expired}`}>
			{t('format.capitalize', {
				text: t('modals.visit.expired.introduction')
			})}
			<UserCard
				className={styles.usercard}
				data={dataAddress}
				theme={ThemeUserCard.SQUARE}
			/>
			<div
				className={styles.footer_buttons}
			>
				<Button
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.visit_canceled')
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					type={EnumButtonType.RESET}
					onClick={handleOnClickCancel}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.visit_confirmed')
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					type={EnumButtonType.SUBMIT}
					onClick={handleOnClickConfirm}
				/>
			</div>
		</div>
	);
}

export default withTranslation()(ModalVisitExpired);
