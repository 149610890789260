import React, {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';

// COMPONENT
import {
	Tooltip as ReactTooltip,
} from 'react-tooltip';

// STYLES
import styles from './tooltip-modal.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import {
	type TooltipProps,
} from '@components/tooltip';
import useMouseEnterLeaveEffect from '@hooks/useMouseEnterLeaveEffect/hook.useMouseEnterLeaveEffect';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

const TooltipModal = ({
	children,
	className,
	'data-testid': dataTestid,
	delayHide = 200,
	delayShow = 500,
	disabled = false,
	htmlElement,
	id,
	offset = 10,
	openOnClick = true,
	place,
}: TooltipProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);

	const cssClasses = [
	];

	if (className) cssClasses.push(className);

	const [
		isTooltipOpen,
		setIsTooltipOpen
	] = useState(false);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setIsTooltipOpen(!isTooltipOpen);
		document.body.click();
	};

	const handleHideDropdown = () => {
		setIsTooltipOpen(false);
	};

	const [
		isActive
	] = useMouseEnterLeaveEffect(ref, false);

	useEffect(() => {
		const timer = !isActive && setTimeout(handleHideDropdown, APP_CONF_VARS.timeout.mouseleave);
		if (isActive && !openOnClick) {
			setIsTooltipOpen(true);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [
		isActive
	]);

	return (

		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			ref={ref}
		>
			<a
				data-testid={`${dataTestid}-trigger`}
				data-tooltip-id={id}
				data-tooltip-offset={offset}
				onClick={handleOnClick}
			>
				{children}
			</a>
			{disabled ? null : (
				<ReactTooltip
					className={styles.customTooltip}
					clickable={true}
					data-testid={`${dataTestid}-tooltip`}
					delayHide={delayHide}
					delayShow={delayShow}
					float={false}
					id={id}
					isOpen={isTooltipOpen}
					noArrow={true}
					offset={offset}
					opacity={1}
					place={place}
					variant='light'
				>
					{htmlElement}
				</ReactTooltip>
			)}
		</div>
	);
};

export {TooltipModal as default, TooltipProps,};

