import * as React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Form from '@components/form';
import InputHidden from '@components/form/input-hidden';
import Button from '@components/button';

// STYLES
import styles from './validation-form.module.scss';

const ValidationForm = ({offerState}: Record<string, unknown>): JSX.Element => {
	const {t} = useTranslation();

	// eslint-disable-next-line
	const onSubmitSuccess = (data: Record<string, unknown>) => {
		console.log(data);
	};

	// eslint-disable-next-line
	const onSubmitError = (data: Record<string, unknown>) => {
		console.log(data);
	};

	const offerStateJson = JSON.stringify(offerState);

	return (
		<Form
			className={styles.form}
			noValidate={true}
			onError={onSubmitError}
			onSuccess={onSubmitSuccess}
		>
			<InputHidden
				defaultValue={offerStateJson}
				id="entry_json"
				name='entry_json'
				required={true}
			/>
			<div className={styles.button_group}>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.cancel')
					})}
					theme={EnumTheme.SECONDARY}
					type={EnumButtonType.BUTTON}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.continue')
					})}
					type={EnumButtonType.SUBMIT}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(ValidationForm);
