import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useNavigate,
} from 'react-router-dom';
import {
	useDispatch,
} from 'react-redux';

// ROUTES
import PATHS from '@routes/paths';
import FETCHES from '@routes/fetches';

// STORE
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	userLogout,
} from '@stores/_slices/user';

// EXCEPTIONS
import GetDemandsByBrokerError from '@exceptions/GetDemandsByBrokerError';

// MODULES
import utils from '@modules/utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
} from '@components/button';
import Paragraph from '@components/paragraph';
import InputCheckbox from '@components/form/input-checkbox';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// LAYOUTS
import {
	optionsState,
	optionsActivitiesIds,
	optionsNegociator,
} from '@layouts/Pages/PageDemands/config/filtersData.config';
import {
	SelectedType,
} from '@layouts/Pages/PageDemands/index';

// STYLES
import styles from './ModalDemandsFiltersMobile.module.scss';

interface SelectedModalType {
	[key: string]: string[] | number;
	activities: string[];
	broker: string[];
	state: string[];
	counter: number;
}

interface ModalDemandsFiltersMobileProps {
    currentUserId?: number;
    getDemandsByFilter?: (brokerIds?: string, stateIds?: string[], activitiesIds?: string, controller?: AbortController) => void;
    isDesktopResolution?: boolean;
	setIsOpenFilterModal?: (isOpenFilterModal: boolean) => void;
	isOpenFilterModal?: boolean;
	setSelected?: Dispatch<SetStateAction<SelectedType>>;
	selected?: SelectedType;
	setHasUsedFilter?: Dispatch<SetStateAction<boolean>>;
}

const ModalDemandsFiltersMobile = ({
	currentUserId,
	setIsOpenFilterModal,
	setSelected,
	setHasUsedFilter,
	selected
}: ModalDemandsFiltersMobileProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [
		brokers,
		setBrokers
	] = useState([
	]);

	const [
		selectedModal,
		setSelectedModal
	] = useState({
		activities: selected?.activities?.length ? selected.activities.split(',') : '',
		broker: selected?.broker?.length ? selected.broker.split(',') : '',
		state: selected?.state,
		counter: selected?.counter,
	} as SelectedModalType);

	const getBrokers = async ()	=> {
		await fetch(utils.getURL(FETCHES.private.demands.brokers), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			if (responseParsed.status === 200) {
				setBrokers(responseParsed.payload);
			} else if (responseParsed.error === 'access_denied') {
				dispatch(userLogout());
				dispatch(clearDemands());
				dispatch(addStatusMsg({
					message: t('status.error_session'),
					theme: EnumStatusTheme.ERROR,
				}));
			} else {
				navigate(PATHS.ERROR._ROOT.replace(':code', '404'));
				throw new GetDemandsByBrokerError(responseParsed.message);
			}
		}).catch(error => {
			throw new GetDemandsByBrokerError(error);
		});
	};

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			dispatch(clearToastMsgs());
			dispatch(clearStatusMsgs());
			getBrokers();
		}
	}, [
	]);

	const handleOnClickClearButton = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setHasUsedFilter(false);
		setSelectedModal({
			activities: [
			],
			broker: [
				`${currentUserId}`
			],
			state: [
				'active'
			],
			counter: 2
		});
	};

	const handleOnClickSubmitButton = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setHasUsedFilter(true);
		setIsOpenFilterModal(false);
		setSelected({
			activities: selectedModal?.activities?.length ? selectedModal.activities.join(',') : '',
			broker: selectedModal?.broker?.length  ? selectedModal.broker.join(',') : '',
			state: selectedModal.state,
			counter: selectedModal.counter
		});
	};

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, property: 'state' | 'broker' | 'activities') => {
		event.preventDefault();
		const value = event.target.id;
		const isSelected = selectedModal[property].includes(value);
		let updatedCounter = selectedModal.counter;

		if (isSelected) {
			updatedCounter--;
			const updated = selectedModal[property].filter((id: string) => id !== value);
			setSelectedModal({
				...selectedModal,
				[property]: updated,
				counter: updatedCounter
			});
		} else {
			updatedCounter++;
			setSelectedModal({
				...selectedModal,
				[property]: [
					...selectedModal[property],
					value
				],
				counter: updatedCounter
			});
		}
	};

	const filterElement = (
		<div className={styles.filters}>
			<h3 className={styles.title}>{t('format.capitalize', {
				text: t('page.demands.filter.labels.state')
			})}</h3>
			<div className={styles.input__state}>
				{optionsState.map((option) => (
					<InputCheckbox
						checked={selectedModal.state.includes(option.value)}
						className={styles.checkbox}
						id={option.id}
						key={option.value}
						label={option.label}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'state')}
					/>
				))}
			</div>
			<h3 className={styles.title}>{t('format.capitalize', {
				text: t('page.demands.filter.labels.negociator')
			})}</h3>
			<div className={styles.input__state}>
				{optionsNegociator(brokers).map((option) => (
					<InputCheckbox
						checked={selectedModal.broker.includes(option.value)}
						className={styles.checkbox}
						id={option.id}
						key={option.value}
						label={option.label}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'broker')}
					/>
				))}

			</div>
			<h3 className={styles.title}>{t('format.capitalize', {
				text: t('page.demands.filter.labels.activities')
			})}</h3>
			<div className={styles.input__activities}>
				{optionsActivitiesIds.map((option) => (
					<InputCheckbox
						checked={selectedModal.activities.includes(option.value)}
						className={styles.checkbox}
						id={option.id}
						key={option.value}
						label={option.label}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'activities')}
					/>
				))}
			</div>
		</div>
	);

	return (
		<div className={`${styles.demands_filters_mobile}`}>
			<Paragraph
				className={styles.modal_content}
			>
				{brokers.length ? filterElement : null}
			</Paragraph>
			<div
				className={styles.footer_buttons}
			>
				<Button
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.reset')
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					type={EnumButtonType.RESET}
					onClick={handleOnClickClearButton}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					type={EnumButtonType.SUBMIT}
					onClick={handleOnClickSubmitButton}
				/>
			</div>
		</div>
	);
};

export default ModalDemandsFiltersMobile;
