import * as React from 'react';
import {
	lazy,
} from 'react';
import {
	Navigate,
} from 'react-router-dom';
import PATHS from '@routes/paths';
import DEMAND_ROUTES from '@routes/lpdipro/private/demands';
import TEST_ROUTES from '@routes/lpdipro/private/tests';

// LAZY LOADING
const PageAppPrivate = lazy(() => import('@layouts/Pages/PageAppPrivate'));
const PageDemand = lazy(() => import('@layouts/Pages/PageDemand'));
const PageDemands = lazy(() => import('@layouts/Pages/PageDemands'));
const PageError = lazy(() => import('@layouts/Pages/PageError'));
const PageExportPDF = lazy(() => import('@layouts/Pages/PageExportPDF'));
const PageHome = lazy(() => import('@layouts/Pages/PageHome'));
const PageLogout = lazy(() => import('@layouts/Pages/PageLogout'));
const PageMatchingOfferDemands = lazy(() => import('@layouts/Pages/PageMatchingOfferDemands'));
const PageObservatoryReport = lazy(() => import('@layouts/Pages/PageObservatoryReport'));
const PageTests = lazy(() => import('@layouts/Pages/PageTests'));
const PageTestsIframe = lazy(() => import('@layouts/Pages/PageTests/Iframe'));
const PageOfferEntry = lazy(() => import('@layouts/Pages/PageOfferEntry'));
const Location = lazy(() => import('@layouts/Pages/PageOfferEntry/location'));

const PRIVATE_ROUTES = [
	{
		element: <PageError />,
		path: PATHS.ERROR._ROOT,
	},
	{
		element: <PageLogout />,
		path: PATHS.LOGOUT,
	},
	{
		element: <PageHome />,
		path: PATHS.HOMEPAGE,
	},
	{
		element: <Navigate to={PATHS.HOMEPAGE} />,
		path: '/',
	},
	{
		element: <PageObservatoryReport />,
		path: PATHS.OBSERVATORY.REPORT,
	},
	{
		element: <PageDemand />,
		children: DEMAND_ROUTES
	},
	{
		element: <PageDemands />,
		path: PATHS.DEMANDS._ROOT
	},
	{
		element: <PageMatchingOfferDemands />,
		path: PATHS.OFFERS.MATCHING_DEMANDS,
	},
	{
		element: <PageOfferEntry />,
		children: [
			{
				path: PATHS.OFFERS.NEW,
				element: <Location />,
			},
		],
	},
];

const PRIVATE_ROUTES_REDIRECT = [
	{
		element: <Navigate to={PATHS.HOMEPAGE} />,
		path: `${PATHS.LOGIN._ROOT}/*`,
	},
	{
		element: <Navigate to={(PATHS.ERROR._ROOT).replace(':code', '404')} />,
		path: '*',
	}
];

let PRIVATE_ROUTES_GLOBAL = [
	{
		element: <PageAppPrivate />,
		children: [
			...PRIVATE_ROUTES,
			...PRIVATE_ROUTES_REDIRECT,
		],
	},
	// TO GET BACK TO @routes/lpdipro/private/tests FILE WHEN FIX IS DONE
	{
		element: <PageTestsIframe />,
		path: PATHS.TESTS.IFRAME,
	},
	{
		element: <PageExportPDF />,
		path: PATHS.OFFERS.EXPORT_PDF,
	},
];

const PRIVATE_ROUTES_TESTS = [
	{
		element: <PageTests />,
		children: TEST_ROUTES
	}
];

if (process.env.NODE_ENV !== 'production') {
	PRIVATE_ROUTES_GLOBAL = [
		...PRIVATE_ROUTES_GLOBAL,
		...PRIVATE_ROUTES_TESTS
	];
}

export {
	PRIVATE_ROUTES_GLOBAL as default,
	PRIVATE_ROUTES,
	PRIVATE_ROUTES_TESTS,
};
