import React, {
	Dispatch,
	SetStateAction,
	useRef,
	useState,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	Outlet,
} from 'react-router-dom';

// REDUCERS
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// SLICES
import {
	DeviceDisplays, DeviceOrientations,
} from '@stores/_slices/device';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageOfferEntry/header';

// STYLES
import styles from './PageOfferEntry.module.scss';

export interface PageOfferContextProps {
	offerState?: Record<string, unknown>;
	setOfferState: (Dispatch<SetStateAction<Record<string, unknown>>>);
	demandIsLoaded: boolean;
	isDesktopResolution: boolean;
}

const PageOfferEntry = () => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const contentEntryOfferRef = useRef(null);

	const [
		offerState,
		setOfferState
	] = useState(undefined);

	return (
		<Page className={styles.PageOfferEntry}>
			<div
				className={styles.PageOfferEntry__content}
				ref={contentEntryOfferRef}
			>
				<Header
					contentEntryOfferRef={contentEntryOfferRef}
					isDesktopResolution={isDesktopResolution}
				/>
				<Outlet
					context={{
						offerState: offerState || {
							test: 'test'
						},
						setOfferState: setOfferState,
						isDesktopResolution: isDesktopResolution,
					}}
				/>
			</div>
		</Page>
	);
};

export default withTranslation()(PageOfferEntry);
