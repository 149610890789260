// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UmHaX0im{display:flex}.JuQ6l2MF{display:flex;flex-direction:column}@media screen and (min-width: 768Px)and (orientation: landscape){.JuQ6l2MF{flex-direction:row}}.JuQ6l2MF .RF7q9MNq{flex:1;margin-bottom:0}@media screen and (min-width: 768Px)and (orientation: landscape){.JuQ6l2MF .RF7q9MNq:first-child{margin-right:0.71428571rem}.JuQ6l2MF .RF7q9MNq:last-child{margin-left:0.71428571rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `UmHaX0im`,
	"button_group": `JuQ6l2MF`,
	"button": `RF7q9MNq`
};
export default ___CSS_LOADER_EXPORT___;
