// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J20MQT2M{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;max-height:-webkit-max-content;max-height:max-content;padding-bottom:2.28571429rem;padding-top:1.42857143rem;width:37.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.J20MQT2M{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.J20MQT2M{padding:2.85714286rem}}.J20MQT2M .K3LBtqk6{display:flex;flex-direction:column;justify-content:space-between;margin-bottom:1.42857143rem}@media screen and (min-width: 768Px)and (orientation: landscape){.J20MQT2M .K3LBtqk6{border-bottom:0.07142857rem solid var(--color-border-default);flex-direction:row;padding-bottom:1.42857143rem}}.J20MQT2M .K3LBtqk6 button{flex:1}.J20MQT2M .K3LBtqk6 button:first-child{margin-bottom:0.57142857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.J20MQT2M .K3LBtqk6 button:first-child{margin-bottom:0;margin-right:0.71428571rem}}.J20MQT2M .YJAgbMLO b{font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_share": `J20MQT2M`,
	"buttons": `K3LBtqk6`,
	"status_banner": `YJAgbMLO`
};
export default ___CSS_LOADER_EXPORT___;
