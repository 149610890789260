import * as React from 'react';

import {
	ChangeEvent,
	MouseEvent,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import PATHS from '@routes/paths';

// HOOKS
import useClickOutsideEffect from '@hooks/useClickOutsideEffect/hook.useClickOutsideEffect';

// EXCEPTIONS
import SearchWithApiError from '@exceptions/SearchWithApiError';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';
import InputText, {
	InputTextProps,
} from '@components/form/input-text';
import Link from '@components/link';
import Loader from '@components/loader';
import Text from '@components/text';

// MODULES
import utils from '@modules/utils';
import utilsText from '@modules/text';

// STYLING
import styles from './input-search-with-dropdown.module.scss';

interface dataToShowProps {
	[key: string]: string | object;
	activity?: string;
	address?: string;
	company?: {
		name?: string;
	};
	data_type?: string;
	icon?: 'building' | 'users' | 'building-people';
	link?: string;
	name?: string;
	type?: string;
	staff?: never | null;
}

interface searchProps extends dataToShowProps {
	hits?: {
		data?: dataToShowProps | Array<dataToShowProps>;
	};
}
interface InputSearchWithDropdownProps extends InputTextProps {
	api?: {
		url?: string;
	};
	hasShadow?: boolean;
	iconIsToggled?: boolean;
	restrictedElement?: ReactElement;
	onClickOutside?: (event: MouseEvent<HTMLInputElement>) => void;
	onClickResult?: (event: MouseEvent<HTMLInputElement>, dataToShow: dataToShowProps) => void;
	onDisplayResults?: (data: dataToShowProps) => void;
	onError?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputSearchWithDropdown = ({
	'data-testid': dataTestid,
	defaultValue,
	disabled = false,
	hasBorder = true,
	hasShadow = false,
	initialValue,
	restrictedElement,
	onClickOutside,
	onClickResult,
	onChange,
	onDisplayResults,
	onError,
	size,
	...otherProps
}: InputSearchWithDropdownProps): JSX.Element => {
	const { t } = useTranslation();

	const hookRef = useRef();
	const ref = hookRef;

	const classes = [
		styles.search
	];

	size ? classes.push(styles[`${'size__' + size}`]) : null;
	hasShadow ? classes.push(styles.shadow) : null;

	const initialState: InputSearchWithDropdownProps = {
		...otherProps,
		disabled,
		value: (initialValue || defaultValue) as string,
		iconIsToggled: true,
	};
	initialState.iconIsToggled = initialValue?.length ? true : false || defaultValue?.length ? true : false;

	const [
		state,
		setState,
	] = useState(initialState);

	const initialStateResults: searchProps = {
	};

	const [
		searchResults,
		setSearchResults
	] = useState(initialStateResults);

	const [
		suggestions,
		setSuggestions
	] = useState([
	]);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	const cssClassesPanel = [
		styles.panel
	];

	const cssClassesLoaderElement = [
		styles.paragraph,
	];

	// Loader to display when search request is pending
	const loaderElement = (
		<li
			className={cssClassesLoaderElement.join(' ')}
			key='loader'
		>
			<Loader message={t('component.input.autosuggest.searching')} />
		</li>
	);

	//Manage click on icon to clear input value
	const handleOnClickClearSearch = (event: MouseEvent<HTMLElement>) => {
		const icon = event.currentTarget;
		const input = icon.closest('div').querySelector('input');
		input.value = null;
		const newState: InputSearchWithDropdownProps = {
			...state,
			iconIsToggled: false,
			value: null,
		};
		setState(newState);
	};

	const cssClassesIconRightElement = [
		styles.icon,
		styles.icon__right,
	];
	const iconRightElement = (
		<Icon
			className={cssClassesIconRightElement.join(' ')}
			data-testid={`${dataTestid}-clearSearchIcon`}
			fontStyle={EnumFontStyle.LIGHT}
			name='times'
			onClick={handleOnClickClearSearch}
		/>
	);

	const cssClassesNoResultsElement = [
		styles.paragraph,
	];
	const noResultsElement = (
		<li
			className={cssClassesNoResultsElement.join(' ')}
			data-testid={`${dataTestid}-noresults`}
			key='noresult'
		>
			<Icon
				className={styles.itemIcon}
				fontStyle={EnumFontStyle.LIGHT}
				name='search-slash'
			/>&nbsp;
			<span
				dangerouslySetInnerHTML={{
					__html: t('component.input.autosuggest.noresult', {
						url: PATHS.SEARCH.BUILDING.LEGACY
					})
				}}
			/>
		</li>
	);

	// PANEL
	const autoSuggestPanel = suggestions.length && !disabled ? (
		<div
			className={cssClassesPanel.join(' ')}
			data-testid={`${dataTestid}-results`}
		>
			<ul
				className={styles.list}
				data-testid={`${dataTestid}-results-list`}
			>
				{suggestions}
			</ul>
		</div>
	) : null;

	// Manage click outside input
	const handleOnClickOutside = (event: MouseEvent<HTMLInputElement>) => {
		setSuggestions([
		]);
		if (onClickOutside) onClickOutside(event);
	};
	useClickOutsideEffect([
		ref
	], handleOnClickOutside);

	const searchWithApi = useCallback(async (value: string, controller: { signal?: AbortSignal }) => {
		/* istanbul ignore next */
		const onRequestSearchError = (error: ChangeEvent<HTMLInputElement>) => {
			if (onError) onError(error);
			setSuggestions([
				noResultsElement
			]);
		};

		try {
			await fetch(`${state.api.url}`, {
				...APP_CONF_VARS.request.default,
				method: 'POST',
				signal: controller.signal,
				headers: {
					...APP_CONF_VARS.request.default.headers,
				},
				body: JSON.stringify({
					q: value,
					filter: /* istanbul ignore next */ value.split(' ').length && value.split(' ').length < 2 ? 'data_type=\'company\'' : '' // TEMPORARY
				})
			})
				.then((resp) => {
					return resp.json();
				})
				.then(responseParsed => {
					if (responseParsed.status >= 400 && responseParsed.status <= 500) {
						throw new SearchWithApiError(responseParsed);
					} else {
						// This test exists to avoid previous request which can make more time to respond
						const newResults = {
							...responseParsed?.payload
						};
						setSearchResults(newResults);
					}
				});
		} catch (error) {
			/* istanbul ignore next */
			if (error.name !== 'AbortError') {
				onRequestSearchError(error);
			}
		}
	}, [
	]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, actualState: InputSearchWithDropdownProps) => {
		const target = event.currentTarget;
		const newState: InputSearchWithDropdownProps = {
			...state,
			...actualState,
			iconIsToggled: target.value.length ? true : false
		};
		setState(newState);
		if (onChange) onChange(event, newState);
	};

	const autosuggestRestrictedElement = restrictedElement ? (
		<li
			className={styles.autosuggest__item}
			data-testid={`${dataTestid}-restricted`}
			key='autosuggest__restricted'
		>
			{restrictedElement}
		</li>
	) : null;

	const handleOnChangeValue = (value: string, controller: object) => {
		const clearedInputValue = value?.replace(/\s/g, '');

		if (clearedInputValue?.length) {
			const isThereNotEnoughChars = clearedInputValue.length < APP_CONF_VARS.autosuggest.minNbInputChars;
			if (isThereNotEnoughChars) {
				// When field value length is under X chars
				setSuggestions([
				]);

				const cssClasses = [
					styles.paragraph,
				];
				// Empty HTML element
				const missingCharsElement = (
					<li
						className={cssClasses.join(' ')}
						data-testid={`${dataTestid}-notenough`}
						key='autosuggest__missingchars'
					>
						{t('component.input.autosuggest.missingchars', {
							count: APP_CONF_VARS.autosuggest.minNbInputChars
						})}
					</li>
				);

				setSuggestions([
					autosuggestRestrictedElement ? autosuggestRestrictedElement : missingCharsElement
				]);
			} else {
				// When field value length is equal or above X chars
				if (autosuggestRestrictedElement) {
					setSuggestions([
						autosuggestRestrictedElement
					]);
				} else {
					setSuggestions([
						loaderElement
					]);

					// Execute the created function
					searchWithApi(value, controller);
				}
			}
		} else {
			// When field value is empty
			setSuggestions([
			]);
		}
	};

	useEffect(() => {
		if (suggestions.length && !disabled) {
			setCssClasses([
				...cssClasses,
				styles.results,
			]);
		} else {
			setCssClasses([
				...cssClasses.filter(cssClass => ![
					styles.results,
				].includes(cssClass))
			]);
		}
	}, [
		suggestions,
		disabled
	]);

	useEffect(() => {
		const controller = new AbortController();
		handleOnChangeValue(state.value, controller);
		return () => controller.abort();
	}, [
		state.value
	]);

	useEffect(() => {
		let arrayItems: Array<ReactElement> = [
		];

		if (searchResults?.hits?.data) {
			/* istanbul ignore next */
			if (onDisplayResults) onDisplayResults(searchResults.hits.data as dataToShowProps);

			if (searchResults.hits.data.length) {
				const allowedDataTypes = [
					'building',
					'company',
					'implantation'
				];
				const filteredResults = (searchResults.hits.data as Array<searchProps>)
					.filter((data) => allowedDataTypes.includes(data.data_type))
					.slice(0, APP_CONF_VARS.autosuggest.maxNbResults);

				arrayItems = filteredResults.map((resultSearchItem: searchProps, index: number) => {
					// WARNING !
					// dataToShow properties order is important because it affects data order in results display
					let dataToShow: dataToShowProps = {
						link: utils.getURL(resultSearchItem.link),
						type: resultSearchItem.data_type
					};

					switch (resultSearchItem.data_type) {
						case 'building':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'building',
								name: resultSearchItem.name || null,
								address: resultSearchItem.address,
							};
							break;
						case 'company':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'users',
								name: resultSearchItem.name,
								staff: resultSearchItem.staff ? t('general.employee', {
									count: resultSearchItem.staff
								}) : null,
								activity: resultSearchItem.activity,
							};
							break;
						case 'implantation':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'building-people',
								name: resultSearchItem.company.name || null,
								staff: resultSearchItem.staff ? t('general.employee', {
									count: resultSearchItem.staff
								}) : null,
								address: resultSearchItem.address || null,
							};
							break;
					}

					let resultsString: string = null;
					const results: Array<string> = [
					];
					Object.keys(dataToShow).forEach((dataToShowKey: string) => {
						let result = null;

						// TOP AVOID EMPTY VALUES
						if (!dataToShow[dataToShowKey as string]) {
							return;
						}

						switch (dataToShowKey) {
							case 'address':
								result = utilsText.format(dataToShow[dataToShowKey], 'address');
								break;
							case 'link':
							case 'icon':
							case 'type':
								// DO NOTHING HERE WITH THESE PROPERTIES
								break;
							default:
								(result as string | object) = dataToShow[dataToShowKey];
						}
						results.push(result);
					});
					const resultsArray = results.filter((result: string) => result?.length);
					resultsString = resultsArray.join(', ');

					// START HIGHLIGHT PROCESS
					// Clean the query string then split it in array
					const queryStringWords: Array<string> = state.value.replace(/[.,/#!$%^&*;:{}=\-_`~()'"]/g, '').replace(/\s{2,}/g, ' ').split(' ').filter(result => result?.length);
					const resultStringWords: Array<string> = resultsString.replace(/[.,/#!$%^&*;:{}=\-_`~()'"]/g, '').replace(/\s{2,}/g, ' ').split(' ').filter((result: string) => result?.length);

					// Function to split the input string into text and HTML tag chunks
					const splitInput = (str: string) => str.split(/(<\/?b>)/gi).filter(Boolean);

					// Check the levenshtein distance for each results words from query words
					resultStringWords.forEach((resultStringWord: string) => {
						const isClose = utilsText.isWordCloseAtLeastOneWordFromWordlist(resultStringWord, queryStringWords);
						if (isClose) {
							const regExp = new RegExp(resultStringWord, 'gi');
							resultsString = resultsString.replace(regExp, (m: string) => `<b>${m}</b>`);
						}
					});

					// Check the strict correspondance with query words and results words
					queryStringWords.forEach(queryStringWord => {
						// Regular expression to ensure that the word is not inside a non-b HTML tag
						// and not followed by a non-tag character until the next HTML opening tag
						const regex = new RegExp(`(?:^|>)([^<]*?)\\b(${queryStringWord})\\b([^<]*?)(?=<|$)`, 'gi');
						resultsString = splitInput(resultsString).map((chunk: string) => {
							// If the chunk is already inside a <b></b> tag, leave it as is
							/* istanbul ignore next */
							if (chunk.startsWith('<b>') && chunk.endsWith('</b>')) {
								return chunk;
							}
							// If the chunk is text, apply the replacement
							return chunk.replace(regex, (match, before, word, after) => `${before}<b>${word}</b>${after}`);
						}).join('');
					});
					// END HIGHLIGHT PROCESS

					const linkContent = (
						<span
							className={styles.ellipsis}
							dangerouslySetInnerHTML={{
								__html: resultsString
							}}
						></span>
					);

					return (
						<li
							className={styles.autosuggest__item}
							data-testid={`${dataTestid}-autosuggest-item`}
							key={index}
						>
							<Link
								className={styles.link} // urls are not on the same scope as BRUCE app so we use them as external links
								data-testid={`${dataTestid}-autosuggest-item-link`}
								fontWeight={EnumFontStyle.REGULAR}
								href={dataToShow.link}
								target={EnumTargetLink.BLANK}
								theme={EnumThemeLink.NAKED}
								onClick={(event: MouseEvent<HTMLInputElement>) => {
									onClickResult ? onClickResult(event, dataToShow) : undefined;
								}}
							>
								<Icon
									className={styles.itemIcon}
									fontStyle={EnumFontStyle.LIGHT}
									name={dataToShow.icon}
								/>&nbsp;
								<Text
									className={styles.itemText}
								>
									<span className={styles.itemType}>{t(`general.form.input.type.search.${dataToShow.type}`)} </span>
									{linkContent}
								</Text>
							</Link>
						</li>
					);
				});
				setSuggestions(arrayItems);
			} else {
				setSuggestions([
					noResultsElement
				]);
			}
		}
	}, [
		searchResults
	]);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={`${dataTestid}-theme-option`}
			ref={ref}
		>
			<div>
				<InputText
					{...otherProps}
					data-testid={`${dataTestid}-search`}
					disabled={disabled}
					hasBorder={hasBorder}
					iconLeft='search'
					iconRight={null}
					initialValue={initialValue as string || defaultValue as string}
					size={size}
					type={EnumInputType.TEXT}
					onChange={handleOnChange}
				/>
				{state.iconIsToggled && !disabled ? iconRightElement : null}
			</div>
			{autoSuggestPanel}
		</div>
	);
};

InputSearchWithDropdown.displayName = EnumComponentType.INPUT_SEARCH_WITH_DROPDOWN;

export {
	InputSearchWithDropdown as default,
	InputSearchWithDropdownProps,
	dataToShowProps,
};
