// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SV5ckfGk{font-size:1rem;margin:0;position:relative}.SV5ckfGk input{display:none}.ygUPGGs6{margin-left:0.92857143rem}.BIMWIcHY{border-radius:0.57142857rem;position:relative}.qc6gxSSk input{padding:0.85714286rem 4rem 0.85714286rem 1.28571429rem}.qIiM4Z6V{color:var(--color-status-error);font-size:0.85714286rem;margin-top:0.35714286rem;text-align:left}.tp7ViI5a{color:currentcolor}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_radio_group": `SV5ckfGk`,
	"input_radio_group__text": `ygUPGGs6`,
	"input_radio_group__field_wrapper": `BIMWIcHY`,
	"input_radio_group__icon": `qc6gxSSk`,
	"error": `qIiM4Z6V`,
	"focused": `tp7ViI5a`
};
export default ___CSS_LOADER_EXPORT___;
