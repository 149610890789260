// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vrRbWYCZ{background-color:var(--color-background-quinary);padding:0;position:relative}.eOw9BcBp{display:flex;flex-direction:column}@media screen and (min-width: 768Px)and (orientation: landscape){.eOw9BcBp{height:calc(100vh - 4.57142857rem);overflow-y:auto}}.vrRbWYCZ main{height:100%}.vrRbWYCZ .jJJ9BHeR{color:var(--color-primary);font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageOfferEntry": `vrRbWYCZ`,
	"PageOfferEntry__content": `eOw9BcBp`,
	"step_progress": `jJJ9BHeR`
};
export default ___CSS_LOADER_EXPORT___;
