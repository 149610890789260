import React, {
	useEffect,
	useState,
} from 'react';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	PowerBIEmbed,
	EmbedProps,
} from 'powerbi-client-react';
import {
	models,
} from 'powerbi-client';
import {
	ICustomEvent,
} from 'service';
import {
	useDispatch,
} from 'react-redux';
import {
	useTranslation,
} from 'react-i18next';

// SLICES
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	userLogout,
	userUpdateSession,
} from '@stores/_slices/user';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearDemands,
} from '@stores/_slices/demands';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENT
import Loader from '@components/loader';
import {
	ResponseParsedProps, 
} from '@components/form';

// EXCEPTIONS
import GetObservatoryReportError from '@exceptions/GetObservatoryReportError';

// MODULES
import utils from '@modules/utils';

// HOOKS
import useThrottledEffect from '@hooks/useTrottledEffect/hook.useTrottledEffect';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// STYLING
import styles from './PageObservatoryReport.module.scss';

function PageObservatoryReport() {
	const { reportid } = useParams();
	const currentReportId = !isNaN(Number(reportid)) ? Number(reportid) : null;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [
		isLoading,
		setIsLoading
	] = useState(true);

	const [
		activeEvent,
		setActiveEvent
	] = useState(null);

	// eslint-disable-next-line
	const handleOnEvent = (event: ICustomEvent<any>) => {
		setActiveEvent(event.timeStamp);
	};

	// AUTHENTICATION CHECKING ON EVERY ROUTE CHANGE
	const getUserAuth = async(callback?: (payload: ResponseParsedProps) => void) => {
		// Remove every other toast
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());

		// check if server session exists on app load
		await fetch(utils.getURL(FETCHES.public.session), {
			...APP_CONF_VARS.request.default,
		}).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			// Everytime 'redirect_url' is present in the payload response, we must redirect.
			// usecase : when the CGU are not accepted yet by the user
			switch (responseParsed.status) {
				case 200:
					dispatch(userUpdateSession());
					break;
				default:
					dispatch(userLogout());
					dispatch(clearDemands());
					// Manage error message
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}

			// calling getUser callback if exists. Example : use to clear a setInterval
			if (callback) callback(responseParsed);

		}).catch(err => {
			console.warn('ERROR : ', err);
		});
	};

	useThrottledEffect(getUserAuth, [
		activeEvent
	], APP_CONF_VARS.timeout.reload_session);

	const defaultPowerBiConf: EmbedProps = {
		embedConfig: {
			type: 'report',   // Supported types: report, dashboard, tile, visual and qna
			id: null,
			embedUrl: null,
			accessToken: null,
			tokenType: models.TokenType.Embed,
		}
	};

	let counter = 0;
	const getReport = async() => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		setIsLoading(true);
		counter++;
		fetch(utils.getURL(FETCHES.private.observatory.report, {
			':reportid': `${currentReportId}`,
		}), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					setPowerBiConf({
						...defaultPowerBiConf,
						embedConfig: {
							...defaultPowerBiConf.embedConfig,
							id: responseParsed.payload.power_bi_report_id,
							embedUrl: responseParsed.payload.power_bi_embed_url,
							accessToken: responseParsed.payload.access_token,
							settings: responseParsed.payload.settings
						}
					});
					setIsLoading(false);
					break;
				default:
					if (counter < 5) {
						getReport();
					} else if (responseParsed.error === 'access_denied') {
						// STORING THE PREVIOUS ROUTE BEFORE AUTH
						dispatch(appSetRoute({
							name: 'from',
							url: location.pathname
						}));

						dispatch(userLogout());
						dispatch(clearDemands());
						dispatch(addStatusMsg({
							message: t('status.error_session'),
							theme: EnumStatusTheme.ERROR,
						}));
					} else {
						navigate(PATHS.ERROR._ROOT.replace(':code', '404'));
						throw new GetObservatoryReportError(responseParsed.message);
					}
			}
		}).catch((error) => {
			throw new Error(error);
		});
	};

	const [
		powerBiConf,
		setPowerBiConf
	] = useState(null);

	useEffect(() => {
		getReport();
	}, [
		reportid,
	]);

	const powerBiElement = powerBiConf ? (
		<div className={styles.wrapper}>
			<PowerBIEmbed
				cssClassName={'report-style-class'}
				embedConfig={powerBiConf.embedConfig}
				eventHandlers={
					new Map([
						[
							'loaded',
							(event) => handleOnEvent(event)
						],
						[
							'rendered',
							(event) => handleOnEvent(event)
						],
						[
							'error',
							function (event) { throw new GetObservatoryReportError(event.detail); }
						],
						[
							'visualClicked',
							(event) => handleOnEvent(event)
						],
						[
							'pageChanged',
							(event) => handleOnEvent(event)
						],
					])
				}
			/>
		</div>
	) : 'No report found';

	return (
		<div className={styles.PageObservatoryReport}>
			{isLoading ? <Loader /> : powerBiElement}
		</div>
	);
}

export default PageObservatoryReport;
