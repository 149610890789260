import * as React from 'react';
import {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	Row,
} from '@tanstack/react-table';

// ROUTES
import PATHS from '@routes/paths';

// MODULES
import {
	FeatureFlags,
} from '@modules/featureFlags';
import utils from '@modules/utils';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// CONFIG
import columnTableConf from '../config/columnCardView.config';
import {
	APP_CONF_VARS,
	EnumEnvironments,
} from '@appConf/vars.conf';
import featuresFlagsConf from '@appConf/featuresFlags.conf';
import {
	filterValueMappings,
	getOptionsFilters,
} from '../config/filterValueMappings';
import {
	getContacts,
} from '../config/fetch';

// DATA
import tilesLayerData from '@static_data/tiles.layer.data';

// TYPE
import {
	Collection,
	Contact,
	Demand,
	Offer,
	OfferJson,
} from '@@types/index';
import {
	DemandJsonPrivate,
} from '@@types/Demand';
import {
	Feature,
} from 'geojson';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// MODULES
import dataUtils from '@modules/data-utils';
import {
	includesSome,
} from '@modules/filters';

// COMPONENTS
import Button from '@components/button';
import Image from '@components/image';
import Paragraph from '@components/paragraph';
import StatusBanner, {
	StatusBannerProps,
} from '@components/status-banner';
import Table from '@components/table';
import Modal, {
	ModalSize,
} from '@components/modal';

// LAYOUTS
import ModalFull from '@layouts/Modals/ModalFull';
import ModalShare from '@layouts/Modals/ModalShare';
import ModalDemandFiltersMobile from '@layouts/Modals/ModalDemandFiltersMobile';
import CartographyLayout from '@layouts/Cartography';
import ContentLoaderOffers from '@layouts/Pages/PageDemand/manage/card-view/contentLoader/contentLoaderOffers';
import ContentLoaderDescription from '@layouts/Pages/PageDemand/manage/card-view/contentLoader/contentLoaderDescription';
import ContentLoaderSearch from '@layouts/Pages/PageDemand/manage/card-view/contentLoader/contentLoaderSearchCriterias';
import ContentLoaderMap from '@layouts/Pages/PageDemand/manage/card-view/contentLoader/contentLoaderMap';
import PanelDetails from '@layouts/Pages/PanelDetails';
import PrivateCard from '@layouts/OfferCard/Private';
import SearchCriterias from '@layouts/SearchCriterias';
import {
	isContentLoadedProps,
} from '@layouts/Pages/PageDemand/manage';
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';
import Filter from '@layouts/Filter/filter';

// STYLES
import styles from './card-view.module.scss';
import stylesDemand from '../../PageDemand.module.scss';

// IMAGES
import matchedImage from '@assets/images/lpdipro/matched.jpg';
import proposedImage from '@assets/images/lpdipro/proposed.jpg';
import appliedImage from '@assets/images/lpdipro/applied.jpg';
import refusedImage from '@assets/images/lpdipro/refused.jpg';
import ignoredImage from '@assets/images/lpdipro/ignored.jpg';
import noResultFilter from '@assets/images/lpdipro/search.jpg';

interface CardViewProps {
	actualLanguage: string;
	demandGlobalState: DemandJsonPrivate;
	showActions: boolean;
	isContentLoaded: isContentLoadedProps;
	isDesktopResolution: boolean;
	isEventHistoryLoaded: boolean;
	commercializationStep: EnumSteps;
	currentOfferId: number;
	onClickModal: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
	onClickCommercializationStep: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
	onClickOffer: (event: MouseEvent<HTMLElement>, offerId: number) => void;
	onClosePanelDetail: () => void;
	onDisplayOfferDetail: () => void;
	selectedOfferFromList: (offerId: number) => Offer | undefined;
}

const CardView = ({
	actualLanguage,
	demandGlobalState,
	showActions,
	isContentLoaded,
	isDesktopResolution,
	isEventHistoryLoaded,
	commercializationStep,
	currentOfferId,
	onClickModal,
	onClickCommercializationStep,
	onClickOffer,
	onClosePanelDetail,
	onDisplayOfferDetail,
	selectedOfferFromList,
}: CardViewProps): JSX.Element => {
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);
	const { t } = useTranslation();
	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const flags = FeatureFlags(featuresFlagsConf, user);
	const mapRef = useRef(null);
	const segmentUrlCompany = demandGlobalState?.broker_company?.segment_url;
	const sharedDemandToken = demandGlobalState?.shared_demand_token;
	const isPreviewDisabled = demandGlobalState?.shared_demand_token ? false : true;

	const envName: EnumEnvironments = utils.getEnvFromhost();
	const targetedHost: string = APP_CONF_VARS.hosts.mesoffres[envName];
	const publicUrlDemand = utils.getURL(`https://${targetedHost}${APP_CONF_VARS.sfPathMesOffres}${PATHS.DEMANDS.PUBLIC.STEP._ROOT}`, {
		':segmenturl': segmentUrlCompany,
		':step': EnumSteps.PROPOSED,
		':token': sharedDemandToken,
	});
	const previewPublicUrlDemand = `${publicUrlDemand}?preview=true`;
	const [
		isOpenPreviewModal,
		setIsOpenPreviewModal
	] = useState(false);

	const [
		isOpenModalShare,
		setIsOpenModalShare
	] = useState(false);

	const [
		isOpenFilterModal,
		setIsOpenFilterModal
	] = useState(false);

	const [
		mapPopupState,
		setMapPopupState
	] = useState(null);

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const handleOnClosePanelDetail = () => {
		// the mapPopupState is reset to enforce map position reset + closing poup
		setMapPopupState(null);
		onClosePanelDetail();
	};

	const handleOnClickCommercializationStep = (event: MouseEvent<HTMLElement>, args: BodyRequest) => {
		event.stopPropagation();
		handleOnClosePanelDetail();
		setReinitSelection(true);
		onClickCommercializationStep(event, args);
	};

	const handleOnDisplayOfferDetail = () => {
		// the mapPopupState is reset to enforce map position reset + closing poup
		onDisplayOfferDetail();
		const currenrOffer = demandGlobalState?.offers?.collection?.length && currentOfferId ? selectedOfferFromList(currentOfferId) : undefined;
		// To open a popup on the map
		if (currenrOffer?.building?.geo_json) {
			setMapPopupState(currenrOffer);
		}
	};

	useEffect(() => {
		if (currentOfferId && isContentLoaded.offers) {
			handleOnDisplayOfferDetail();
		}
	}, [
		currentOfferId,
		isContentLoaded.offers
	]);

	let commercializationStepImage = null;
	switch (commercializationStep) {
		case EnumSteps.APPLIED:
			commercializationStepImage = appliedImage;
			break;
		case EnumSteps.REFUSED:
			commercializationStepImage = refusedImage;
			break;
		case EnumSteps.IGNORED:
			commercializationStepImage = ignoredImage;
			break;
		case EnumSteps.PROPOSED:
			commercializationStepImage = proposedImage;
			break;
		default:
			commercializationStepImage = matchedImage;
	}

	const headingText = (`${t('format.capitalize', {
		text: t(`page.demand.private.tabs.manage.steps.${commercializationStep as EnumSteps}.title`)
	})}${`\u00A0(${isContentLoaded.demand ? demandGlobalState?.counters?.steps[`${commercializationStep as EnumSteps}`] : null})`}`);

	const headingTextElement = isContentLoaded.demand ? (
		<Paragraph
			className={styles.heading}
			title={headingText}
		>
			{demandGlobalState?.offers?.collection.length ? t('format.capitalize', {
				text: t(`page.demand.private.tabs.manage.steps.${commercializationStep}.description`)
			}) : null}
		</Paragraph >
	) : <div><ContentLoaderDescription /></div>;

	const [
		columnFilters,
		setColumnFilters
	] = useState([
	]);

	const [
		globalFilter,
		setGlobalFilter
	] = useState('');

	const noResultElement = (
		<Paragraph
			className={styles.heading_no_result}
		>
			<div className={styles.message}>
				<Image
					className={styles.images}
					src={columnFilters.length || globalFilter.length ? noResultFilter : commercializationStepImage}
				/>
				{t('format.capitalize', {
					text: columnFilters.length || globalFilter.length ? t('general.filter.no_result_filter.title') : t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result`)
				})}
				<span className={styles.description}>
					<span>
						{t('format.capitalize', {
							text: columnFilters.length || globalFilter.length ? t('general.filter.no_result_filter.description') : t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result_description`)
						})}
					</span>
					<span>
						{columnFilters.length || globalFilter.length ? undefined : t('format.capitalize', {
							text: t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result_modify`)
						})}
					</span>
				</span>
			</div>
		</Paragraph>
	);

	const handleOnChangePagination = (event: MouseEvent<HTMLElement>) => {
		const target = event.target as HTMLElement;
		const content = isDesktopResolution ? target.closest(`.${styles.section__overflow}`) : target.closest(`.${stylesDemand.content}`);
		content.scrollTop = 0;
	};

	const [
		offerSelectedIds,
		setOfferSelectedIds
	] = useState([
	]);

	const commercializationActions = (offer?: Offer, isDetail?: boolean) => {
		const offerIds = offer ? [
			offer.id
		] : offerSelectedIds;
		let actionsList = null;

		const visitButton = {
			badgeTheme: null as EnumStatusTheme,
			iconLeft: 'calendar-plus',
			tooltipText: 'plan_my_visit',
		};
		if (offer?.states?.includes('visit_planned')) {
			visitButton.iconLeft = 'calendar-rotate';
			visitButton.tooltipText = 'update_visit';
		} else if (offer?.states?.includes('visit_expired')) {
			visitButton.iconLeft = 'calendar-question';
			visitButton.tooltipText = 'confirm_visit';
			visitButton.badgeTheme = EnumStatusTheme.ERROR;
		}
		switch (commercializationStep) {
			case EnumSteps.MATCHED: {
				actionsList = (
					<>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'share-arrow'}
							iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={isDesktopResolution && offer ? undefined : t('format.capitalize', {
								text: t('general.action.proposed')
							})}
							name={'proposed'}
							size={EnumButtonSize.SMALL}
							theme={offer && isDesktopResolution ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
							tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
								text: t('general.action.proposed')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'proposed',
									ids: offerIds,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={isDesktopResolution && offer ? undefined : t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='remove'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
								text: t('general.action.ignored')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									ids: offerIds,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.PROPOSED: {
				actionsList = (
					<>
						{offer && isDesktopResolution ? (
							<Button
								badgeTheme={visitButton.badgeTheme}
								className={`separator ${styles.button}`}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={visitButton.iconLeft}
								iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								label={isDesktopResolution ? undefined : t('format.capitalize', {
									text: t('general.action.plan_my_visit')
								})}
								name='plan_my_visit'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
									text: t(`general.action.${visitButton.tooltipText}`)
								}) : undefined}
								onClick={event => {
									event.stopPropagation();
									const newBodyRequest: BodyRequest = {
										address: offer?.building?.address?.full,
										contacts: offer?.contacts,
										demandId: demandGlobalState.id,
										ids: [
											offer.id
										],
										images: offer?.images?.collection[0]?.url,
										name: 'plan_my_visit',
										states: offer?.states,
										visit: offer?.visit,
									};

									getContacts(demandGlobalState.id, offer.id).then((responseParsed) => {
										newBodyRequest.contacts = new Collection(responseParsed.payload, Contact);
										onClickModal(event, newBodyRequest);
									});
								}}
							/>
						) : undefined}
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							disabled={flags?.hasCRM ? true : false}
							hasBoxShadow={true}
							iconLeft={'folder'}
							iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={isDesktopResolution && offer ? undefined : t('format.capitalize', {
								text: t('general.action.applied')
							})}
							name='applied'
							size={EnumButtonSize.SMALL}
							theme={offer && isDesktopResolution ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
							tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
								text: t('general.action.applied')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'applied',
									ids: offerIds,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'times'}
							iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={isDesktopResolution && offer ? undefined : t('format.capitalize', {
								text: t('general.action.refused')
							})}
							name='refused'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
								text: t('general.action.refused')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'refused',
									ids: offerIds,
								});
							}}
						/>
						{isDesktopResolution ? (
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								hasBoxShadow={true}
								iconLeft={'trash-alt'}
								iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								label={offer ? undefined : t('format.capitalize', {
									text: t('general.action.ignored')
								})}
								name='ignored'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
									text: t('general.action.ignored')
								}) : undefined}
								onClick={event => {
									handleOnClickCommercializationStep(event, {
										commercialization_step: 'ignored',
										ids: offerIds,
									});
								}}
							/>
						) : undefined}
					</>
				);
				break;
			}
			case EnumSteps.APPLIED: {
				const classSeparator = isDesktopResolution ? 'separator' : '';
				actionsList = (
					<>
						{offer && isDesktopResolution ? (
							<Button
								badgeTheme={visitButton.badgeTheme}
								className={`${classSeparator} ${styles.button}`}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={visitButton.iconLeft}
								iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								name='plan_my_visit'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
									text: t(`general.action.${visitButton.tooltipText}`)
								}) : undefined}
								onClick={event => {
									event.stopPropagation();
									const newBodyRequest: BodyRequest = {
										address: offer?.building?.address?.full,
										contacts: offer?.contacts,
										demandId: demandGlobalState.id,
										ids: [
											offer.id
										],
										images: offer?.images?.collection[0]?.url,
										name: 'plan_my_visit',
										states: offer?.states,
										visit: offer?.visit,
									};

									getContacts(demandGlobalState.id, offer.id).then((responseParsed) => {
										newBodyRequest.contacts = new Collection(responseParsed.payload, Contact);
										onClickModal(event, newBodyRequest);
									});
								}}
							/>
						) : undefined}
						{offer ? (
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={'folder-grid'}
								iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								label={isDesktopResolution ? undefined : t('format.capitalize', {
									text: t('general.action.apply_progress')
								})}
								name='apply_progress'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={isDesktopResolution  ? t('format.capitalize', {
									text: t('general.action.apply_progress')
								}) : undefined}
								onClick={event => {
									onClickModal(event, {
										ids: [
											offer.id
										],
										name: 'apply_progress',
										states: offer.states,
									});
								}}
							/>
						) : undefined}
						{isDetail ? undefined : (
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={'arrow-back'}
								iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								label={offer && isDesktopResolution ? undefined : t('format.capitalize', {
									text: t('general.action.cancel_apply')
								})}
								name='cancel_apply'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={isDesktopResolution ? t('format.capitalize', {
									text: t('general.action.cancel_apply')
								}) : undefined}
								onClick={event => {
									handleOnClickCommercializationStep(event, {
										commercialization_step: 'proposed',
										ids: offerIds,
									});
								}}
							/>
						)}
					</>
				);
				break;
			}
			case EnumSteps.IGNORED:
			case EnumSteps.REFUSED: {
				actionsList = (
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft={'share-arrow'}
						iconStyle={offer && isDesktopResolution ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
						label={isDesktopResolution && offer ? undefined : t('format.capitalize', {
							text: t('general.action.proposed')
						})}
						name='proposed'
						size={EnumButtonSize.SMALL}
						theme={offer && isDesktopResolution ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
						tooltipText={isDesktopResolution && offer ? t('format.capitalize', {
							text: t('general.action.proposed')
						}) : undefined}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								commercialization_step: 'proposed',
								ids: offerIds,
							});
						}}
					/>
				);
				break;
			}

		}
		return <div className={styles.action_list}>{actionsList}</div>;
	};

	const [
		geoJsonSource,
		setGeoJsonSource
	] = useState(demandGlobalState?.geo_json);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const updateGeoJsonFeaturesFiltered: (filteredOffers: Row<{ [key: string]: any }>[]) => void = (filteredOffers) => {
		const filteredFeatures = demandGlobalState?.geo_json?.features.filter((feature: Feature) =>
			filteredOffers.some((offer) => feature.properties.offer_id === offer.original.id)
		) || [
		];

		setGeoJsonSource({
			...demandGlobalState?.geo_json,
			features: filteredFeatures
		});
	};

	const configurationActionBarTable = {
		type: t('module.offer.name_other'),
		actions: commercializationActions(),
		commercializationStep: commercializationStep,
		demandId: demandGlobalState.id,
	};

	const formatedData = demandGlobalState?.offers?.collection?.map((offer: OfferJson) => {
		return dataUtils.flattenObject(offer);
	});

	const tableTop = (
		<div className={styles.table__top}>
			<Filter
				commercializationStep={commercializationStep}
				filterValueMappings={filterValueMappings}
				getOptionsFilters={getOptionsFilters}
				globalFilter={globalFilter}
				isDesktopResolution={isDesktopResolution}
				setColumnFilters={setColumnFilters}
				setGlobalFilter={setGlobalFilter}
				setIsOpenFilterModal={setIsOpenFilterModal}
			/>
		</div>
	);

	const offersListElement = isContentLoaded.demand && isContentLoaded.offers ? (
		<Table
			actionBarClassName={styles.action_bar__container}
			className={styles.table}
			columnFilters={columnFilters}
			columns={columnTableConf}
			configurationActionBar={configurationActionBarTable}
			customNoResult={noResultElement}
			data={formatedData}
			filterFns={(row) => includesSome(row, columnFilters)}
			globalFilter={globalFilter}
			identifier='table_demand_manage'
			options={{
				hasHeader: false,
				canSelect: isContentLoaded.canSelect,
				pagination: {
					nbItemsPerPage: 10,
					onChange: (event) => {
						handleOnChangePagination(event);
					}
				},
				topChildren: tableTop
			}}
			reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				const filteredOfferJson = demandGlobalState?.offers?.collection?.filter((offer: OfferJson) => offer.id === props.original.id);
				const offer = filteredOfferJson.length ? new Offer(filteredOfferJson[0]) : undefined;
				return offer ? (
					<PrivateCard
						{...props}
						actionsList={props.isSelected === null && showActions ? commercializationActions(offer) : undefined}
						className={styles.table__row}
						configuration={configurationActionBarDetail}
						isDesktopResolution={isDesktopResolution}
						language={actualLanguage}
						offer={offer}
						viewType='card'
						onClick={(event: MouseEvent<HTMLElement>) => {
							onClickOffer(event, offer.id);
						}}
						onClickCommercializationStep={handleOnClickCommercializationStep}
						onClickModal={onClickModal}
					/>
				) : null;
			}}
			setComponentSelectedIds={setOfferSelectedIds}
			setReinitSelection={setReinitSelection}
			theme='column'
			onDataFiltered={updateGeoJsonFeaturesFiltered}
		/>
	) : <ContentLoaderOffers />;

	const [
		sectionMapClassNamesState,
		setSectionMapClassNamesState
	] = useState([
		styles.section,
		styles.section__right
	]);

	const [
		mapSectionIsVisibleState,
		setMapSectionIsVisibleState
	] = useState(false);

	useEffect(() => {
		if (mapSectionIsVisibleState) {
			setSectionMapClassNamesState([
				...sectionMapClassNamesState,
				styles.visible
			]);
		} else {
			const newCss = sectionMapClassNamesState.filter(className => className !== styles.visible);
			setSectionMapClassNamesState(newCss);
		}
	}, [
		mapSectionIsVisibleState
	]);

	// eslint-disable-next-line
	const handleOnClickOnMarker = (event: any) => {
		const offerId = event?.features[0]?.properties?.offer_id;
		const currenrOffer = offerId ? selectedOfferFromList(offerId) : undefined;
		if (currenrOffer?.building?.geo_json) {
			setMapPopupState(currenrOffer);
		}
	};

	const handleOnClosePopup = () => {
		setMapPopupState(null);
	};

	const editUrl = `${APP_CONF_VARS.sfLegacyPath}/offer/searchDemande/editdemand/${demandGlobalState?.id}`;
	const recipientCompany = isContentLoaded.demand ? demandGlobalState.recipient?.company : null;
	const searchCriteriasElement = isContentLoaded.demand ? (
		<SearchCriterias
			className={styles.search_criterias}
			company={recipientCompany}
			data={new Demand(demandGlobalState, actualLanguage)?.search_criterias}
			editUrl={editUrl}
		/>
	) : <ContentLoaderSearch />;

	const mapElement = isContentLoaded.demand && isContentLoaded.map ? (
		<CartographyLayout
			className={styles.map}
			copyright={{
				href: PATHS._ROOT,
				text: t('website.copyright'),
			}}
			geojsonSource={{
				name: 'offers',
				data: geoJsonSource
			}}
			innerRef={mapRef}
			popupData={mapPopupState}
			tilesLayerData={tilesLayerData}
			onClickMarker={handleOnClickOnMarker}
			onClickPopup={(event: MouseEvent<HTMLElement>) => {
				onClickOffer(event, mapPopupState?.offer?.id);
			}}
			onClosePopup={handleOnClosePopup}
		/>
	) : <ContentLoaderMap />;

	const currentOffer = demandGlobalState?.offers?.collection && currentOfferId ? selectedOfferFromList(currentOfferId) : undefined;

	const configurationActionBarDetail = {
		type: t('module.offer.name_other'),
		actions: commercializationActions(currentOffer, true),
		commercializationStep: commercializationStep,
		demandId: demandGlobalState.id,
	};
	const announcementDetail = currentOffer ? (
		<PanelDetails
			className={styles.panel_details}
			configurationActionBar={showActions ? configurationActionBarDetail : undefined}
			data={currentOffer}
			isContentLoaded={isContentLoaded}
			isDesktopResolution={isDesktopResolution}
			isEventHistoryLoaded={isEventHistoryLoaded}
			isPrivate={true}
			viewType='card'
			onClickClose={handleOnClosePanelDetail}
			onClickCommercializationStep={handleOnClickCommercializationStep}
			onClickModal={onClickModal}
		/>
	) : undefined;

	const handleOnClickPreview = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenPreviewModal(true);
	};

	const handleOnClickShare = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModalShare(true);
	};

	const bottomnav = (
		<div className={styles.bottomnav}>
			<Button
				className={styles.active_map}
				corners={EnumButtonCorners.Square}
				iconRight={mapSectionIsVisibleState ? 'list-ul' : 'map-marker-alt'}
				label={t('format.capitalize', {
					text: t(`general.action.${mapSectionIsVisibleState ? 'view_list' : 'view_map'}`)
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				onClick={event => {
					event.preventDefault();
					setMapSectionIsVisibleState(!mapSectionIsVisibleState);
				}}
			/>
			<div className={styles.preview_share}>
				<Button
					corners={EnumButtonCorners.Square}
					disabled={isPreviewDisabled}
					iconLeft={'eye'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					onClick={handleOnClickPreview}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					disabled={isPreviewDisabled}
					iconLeft={'user-arrow'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					onClick={handleOnClickShare}
				/>
			</div>
		</div>
	);

	const statusMsgsElement = statusMsgs.length ? (
		<div
			className={styles.status_banner_container}
			data-status-msg-wrapper
		>
			{statusMsgs.map((statusBanner: StatusBannerProps, index: number) => {
				return (
					<StatusBanner
						buttons={statusBanner.buttons}
						className={styles.status_banner}
						isClosable={statusBanner.isClosable}
						key={index}
						message={statusBanner.message}
						theme={statusBanner.theme}
					/>
				);
			})}
		</div>
	) : null;

	return (
		<>
			{isOpenPreviewModal ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenPreviewModal}
					size={ModalSize.FULL}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_title')
					})}
					onClose={() => {
						setIsOpenPreviewModal(false);
					}}
				>
					<ModalFull>
						<iframe
							src={previewPublicUrlDemand}
						></iframe>
					</ModalFull>
				</Modal>
			) : undefined}
			{isOpenModalShare ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenModalShare}
					size={ModalSize.DEFAULT}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_share_title')
					})}
					onClose={() => {
						setIsOpenModalShare(false);
					}}
				>
					<ModalShare
						demand={demandGlobalState}
						publicUrlDemand={publicUrlDemand}
					/>
				</Modal>
			) : undefined}
			<div className={`${styles.section} ${styles.section__left}`}>
				<div className={`${styles.section} ${styles.section__overflow}`}>
					{statusMsgsElement}
					{headingTextElement}
					{offersListElement}
					{currentOfferId ? announcementDetail : undefined}
					{bottomnav}
				</div>
			</div>
			<div className={sectionMapClassNamesState.join(' ')}>
				{searchCriteriasElement}
				{mapElement}
			</div>
			{isOpenFilterModal ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenFilterModal}
					size={ModalSize.DEFAULT}
					title={t('format.capitalize', {
						text: t('modals.filter.title')
					})}
					onClose={() => {
						setIsOpenFilterModal(false);
					}}
				>
					<ModalDemandFiltersMobile
						columnFilters={columnFilters}
						commercializationStep={commercializationStep}
						setColumnFilters={setColumnFilters}
						setIsOpenFilterModal={setIsOpenFilterModal}
					/>
				</Modal>
			) : undefined}
		</>
	);
};

export default withTranslation()(CardView);
