// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rr97svOA{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.Rr97svOA{padding:1.42857143rem}}.Kqatp2HL{display:flex;flex-direction:column;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.Kqatp2HL{flex-shrink:0;width:40.71428571rem}}.Rr97svOA .e4Cs5zOe{display:flex}.Rr97svOA .e4Cs5zOe .JOIDahjV{margin-left:0.57142857rem}.Rr97svOA .e4Cs5zOe .JOIDahjV:first-child{flex:1;margin-left:0}.Rr97svOA .e4Cs5zOe .OgbP8LTZ{margin-left:0.57142857rem;max-width:3rem}.Rr97svOA .cESrC890{display:flex;justify-content:center;padding-top:2.14285714rem}.IQFsMYe5{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.IQFsMYe5:first-letter{display:block;text-transform:uppercase}.Uny4fN7l{margin-bottom:1.42857143rem;font-size:0.85714286rem}.XTxG4OVM{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Rr97svOA`,
	"section__left": `Kqatp2HL`,
	"form": `e4Cs5zOe`,
	"input": `JOIDahjV`,
	"button": `OgbP8LTZ`,
	"image": `cESrC890`,
	"title": `IQFsMYe5`,
	"legend": `Uny4fN7l`,
	"step_progress": `XTxG4OVM`
};
export default ___CSS_LOADER_EXPORT___;
