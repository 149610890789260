import React from 'react';
import imagesModules from '@modules/images';
import {
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

// COMPONENTS
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// TYPES
import {
	Contact,
} from '@@types/Contact';

export const getItemsContactData = (data: Contact[], theme: ThemeUserCard) => {

	const itemsContact = [
	] as object[];

	data.forEach((contact: Contact, index: number) => {
		const hasSeparator = index !== data?.length - 1;
		const createdUserCardData = {
			title: contact?.full_name,
			id: contact?.id?.toString(),
			image_url: imagesModules.updateImageUrls(contact?.logo?.url, EnumImageFormatQuery.USERCARD) as string,
			descriptions: [
				{
					value: contact?.company_name,
					type: 'subtitle'
				},
				{
					value: contact?.mobile || contact?.phone,
					type: 'tel'
				},
				{
					value: contact?.email,
					type: 'email'
				}
			],

		};

		const itemContact = {
			content: <UserCard
				data={createdUserCardData}
				theme={theme as ThemeUserCard}
				visitData={contact.suggested_contact_for_visit}
			/>,
			separator: hasSeparator,
		};

		itemsContact.push(itemContact);

	});

	return itemsContact;

};
