import * as React from 'react';
import {
	ComponentType,
} from 'react';

// COMPONENTS
import {
	ImageProps,
} from '@components/image';
import {
	InputRadioProps,
} from '@components/form/input-radio-group/input-radio';
import {
	UserCardProps,
} from '@components/user-card';

// HOC
import withRadioButton, {
} from '@hoc/withRadioButton';

interface ComponentWithRadioButtonProps extends InputRadioProps, UserCardProps, ImageProps {
	componentType?: ComponentType;
}

const ComponentWithRadioButton = ({
	checked,
	'data-testid': dataTestid,
	disabled = false,
	id,
	innerRef,
	name,
	componentType,
	...otherProps
}: ComponentWithRadioButtonProps): JSX.Element => {

	const ComponentWithRadioButton = withRadioButton(componentType);

	return (
		<ComponentWithRadioButton
			checked={checked}
			data-testid={dataTestid}
			disabled={disabled}
			id={id}
			innerRef={innerRef}
			name={name}
			{...otherProps}
		/>
	);
};

export {
	ComponentWithRadioButton as default,
	ComponentWithRadioButtonProps,
};
