import * as React from 'react';
import {
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';

// ROUTES
import PATHS from '@routes/paths';
import FETCHES from '@routes/fetches';

// MODULES
import utils from '@modules/utils';
import {
	FeatureFlags,
} from '@modules/featureFlags';
import dataUtils from '@modules/data-utils';
import {
	includesSome,
} from '@modules/filters';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	demandOfferAddContacts,
	demandOfferAddLots,
	demandUpdate,
} from '@stores/_slices/demands';

// CONFIG
import {
	columnTableConf,
} from '@layouts/Pages/PageDemand/manage/config/columnTableView.config';
import {
	APP_CONF_VARS,
	EnumEnvironments,
} from '@appConf/vars.conf';
import featuresFlagsConf from '@appConf/featuresFlags.conf';
import {
	getContacts,
	getLots,
} from '../config/fetch';
import {
	filterValueMappings,
	getOptionsFilters,
} from '../config/filterValueMappings';

// TYPES
import {
	Collection,
	Contact,
	ContactJson,
	Offer,
	OfferJson,
} from '@@types/index';
import {
	DemandJsonPrivate,
} from '@@types/Demand';
import {
	DocumentTemplatesProps,
} from '@@types/Collection';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// COMPONENTS
import Button from '@components/button';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import Image from '@components/image';
import Paragraph from '@components/paragraph';
import StatusBanner, {
	StatusBannerProps,
} from '@components/status-banner';
import Table from '@components/table';
import Modal, {
	ModalSize,
} from '@components/modal';
import ButtonWithDropdown from '@components/button-with-dropdown';
import Filter from '@layouts/Filter/filter';

// LAYOUTS
import ModalFull from '@layouts/Modals/ModalFull';
import ModalShare from '@layouts/Modals/ModalShare';
import ContentLoaderOffers from '@layouts/Pages/PageDemand/manage/table-view/contentLoader/contentLoaderOffers';
import PanelDetails from '@layouts/Pages/PanelDetails';
import PrivateCard from '@layouts/OfferCard/Private';
import {
	isContentLoadedProps,
} from '@layouts/Pages/PageDemand/manage';
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';

// STYLES
import styles from './table-view.module.scss';

// IMAGES
import matchedImage from '@assets/images/lpdipro/matched.jpg';
import proposedImage from '@assets/images/lpdipro/proposed.jpg';
import appliedImage from '@assets/images/lpdipro/applied.jpg';
import refusedImage from '@assets/images/lpdipro/refused.jpg';
import ignoredImage from '@assets/images/lpdipro/ignored.jpg';
import noResultFilter from '@assets/images/lpdipro/search.jpg';

interface TableViewProps {
	actualLanguage: string;
	demandGlobalState: DemandJsonPrivate;
    isContentLoaded: isContentLoadedProps;
	isDesktopResolution: boolean;
    isEventHistoryLoaded: boolean;
    commercializationStep: EnumSteps;
    currentOfferId: number;
    showActions: boolean;
    onClickModal: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
    onClickCommercializationStep: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
    onClickOffer: (event: MouseEvent<HTMLElement>, offerId: number) => void;
    onClosePanelDetail: () => void;
    onDisplayOfferDetail: () => void;
    selectedOfferFromList: (offerId: number) => Offer | undefined;
}

const TableView = ({
	actualLanguage,
	demandGlobalState,
	showActions,
	isContentLoaded,
	isDesktopResolution,
	isEventHistoryLoaded,
	commercializationStep,
	currentOfferId,
	onClickModal,
	onClickCommercializationStep,
	onClickOffer,
	onClosePanelDetail,
	onDisplayOfferDetail,
	selectedOfferFromList,
}: TableViewProps): JSX.Element => {
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);
	const { t } = useTranslation();
	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const flags = FeatureFlags(featuresFlagsConf, user);
	const dispatch = useDispatch();
	const brokers = useSelector((state: ReducerInstance) => state.user.instance.company.brokers);
	const documentTemplates = useSelector((state: ReducerInstance) => state.user.instance.company.document_templates);
	const segmentUrlCompany = demandGlobalState?.broker_company?.segment_url;
	const sharedDemandToken = demandGlobalState?.shared_demand_token;
	const isPreviewDisabled = demandGlobalState?.shared_demand_token ? false : true;

	const currentOffer = demandGlobalState?.offers?.collection && currentOfferId ? selectedOfferFromList(currentOfferId) : undefined;

	const envName: EnumEnvironments = utils.getEnvFromhost();
	const targetedHost: string = APP_CONF_VARS.hosts.mesoffres[envName];
	const publicUrlDemand = utils.getURL(`https://${targetedHost}${APP_CONF_VARS.sfPathMesOffres}${PATHS.DEMANDS.PUBLIC.STEP._ROOT}`, {
		':segmenturl': segmentUrlCompany,
		':step': EnumSteps.PROPOSED,
		':token': sharedDemandToken,
	});
	const previewPublicUrlDemand = `${publicUrlDemand}?preview=true`;

	const [
		isOpenPreviewModal,
		setIsOpenPreviewModal
	] = useState(false);

	const [
		isOpenModalShare,
		setIsOpenModalShare
	] = useState(false);

	const [
		isBookmarked,
		setIsBookmarked
	] = useState(false);

	const [
		offerSelectedIds,
		setOfferSelectedIds
	] = useState<number[]>([
	]);

	const [
		columnFilters,
		setColumnFilters
	] = useState([
	]);

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const [
		globalFilter,
		setGlobalFilter
	] = useState('');

	const headingText = (`${t('format.capitalize', {
		text: t(`page.demand.private.tabs.manage.steps.${commercializationStep as EnumSteps}.title`)
	})}${`\u00A0(${isContentLoaded.demand ? demandGlobalState?.counters?.steps[`${commercializationStep as EnumSteps}`] : null})`}`);

	const tableTop = (
		<div className={styles.table__top}>
			<Paragraph
				className={styles.heading}
				title={headingText}
			>
				{demandGlobalState?.offers?.collection.length ? t('format.capitalize', {
					text: t(`page.demand.private.tabs.manage.steps.${commercializationStep}.description`)
				}) : null}
			</Paragraph >
			<div className={styles.table__top_right}>
				<Filter
					commercializationStep={commercializationStep}
					filterValueMappings={filterValueMappings}
					getOptionsFilters={getOptionsFilters}
					globalFilter={globalFilter}
					isDesktopResolution={isDesktopResolution}
					setColumnFilters={setColumnFilters}
					setGlobalFilter={setGlobalFilter}
				/>
			</div>
		</div>
	);

	const handleOnClosePanelDetail = () => {
		onClosePanelDetail();
	};

	const handleOnClickCommercializationStep = (event: MouseEvent<HTMLElement>, args: BodyRequest) => {
		if (currentOfferId) handleOnClosePanelDetail();
		setReinitSelection(true);
		onClickCommercializationStep(event, args);
	};

	const handleOnDisplayOfferDetail = () => {
		onDisplayOfferDetail();
	};

	useEffect(() => {
		if (currentOfferId && isContentLoaded.offers) {
			handleOnDisplayOfferDetail();
		}
	}, [
		currentOfferId,
		isContentLoaded.offers
	]);

	let commercializationStepImage = null;
	switch (commercializationStep) {
		case EnumSteps.APPLIED:
			commercializationStepImage = appliedImage;
			break;
		case EnumSteps.REFUSED:
			commercializationStepImage = refusedImage;
			break;
		case EnumSteps.IGNORED:
			commercializationStepImage = ignoredImage;
			break;
		case EnumSteps.PROPOSED:
			commercializationStepImage = proposedImage;
			break;
		default:
			commercializationStepImage = matchedImage;
	}

	const noResultElement = isContentLoaded.offers ? (
		<Paragraph
			className={styles.heading_no_result}
		>
			<div className={styles.message}>
				<Image
					className={styles.images}
					src={columnFilters.length || globalFilter.length ? noResultFilter : commercializationStepImage}
				/>
				{t('format.capitalize', {
					text: columnFilters.length || globalFilter.length ? t('general.filter.no_result_filter.title') : t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result`)
				})}
				<span className={styles.description}>
					<span>
						{t('format.capitalize', {
							text: columnFilters.length || globalFilter.length ? t('general.filter.no_result_filter.description') : t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result_description`)
						})}
					</span>
					<span>
						{columnFilters.length || globalFilter.length ? undefined : t('format.capitalize', {
							text: t(`page.demand.private.tabs.manage.steps.${commercializationStep}.no_result_modify`)
						})}
					</span>
				</span>
			</div>
		</Paragraph>
	) : null;

	const handleGetContacts = async (offerId: number) => {
		return await getContacts(demandGlobalState.id, offerId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddContacts({
						demand_id: demandGlobalState.id,
						offer_id: offerId,
						contacts: responseParsed.payload,
					}));
					return responseParsed.payload;
				case 403: {
					console.log('error');
					break;
				}
				default:
			}
		});
	};

	const handleGetLots = async (offerId: number) => {
		return await getLots(demandGlobalState.id, offerId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddLots({
						demand_id: demandGlobalState.id,
						offer_id: offerId,
						lots: responseParsed.payload,
					}));
					return responseParsed.payload;
				case 403: {
					console.log('error');
					break;
				}
				default:
			}
		});
	};

	const setDemandGlobalState = (states: string[], dataId: number) => {
		const updatedOffers: OfferJson[] = [
		];
		let offerUpdated = null;

		demandGlobalState.offers.collection.forEach((offerGlobal: OfferJson) => {
			if (offerGlobal.id === dataId) {
				offerUpdated = {
					...offerGlobal,
					states: states
				};
				updatedOffers.push(offerUpdated);
			} else {
				updatedOffers.push(offerGlobal);
			}
		});

		dispatch(demandUpdate({
			demand: {
				...demandGlobalState,
				offers: {
					...demandGlobalState.offers,
					collection: [
						...updatedOffers,
					]
				}
			},
			demand_id: demandGlobalState.id,
		}));
	};

	const getStates = async (bodyRequest: BodyRequest) => {
		const response = await fetch(utils.getURL(FETCHES.private.demands.offers, {
			':demandid': `${demandGlobalState.id}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify(bodyRequest),
		}).then((resp) => {
			return resp.json();
		}).catch(err => {
			console.warn('ERROR : ', err);
		});

		return await response;
	};

	const handleOnClickModalDownload = (event: MouseEvent<HTMLElement>, offer: Offer) => {
		const newBodyRequest = {
			brokers: brokers?.collection as ContactJson[],
			document_templates: documentTemplates.collection as DocumentTemplatesProps[],
			offerId: offer.id,
		};

		if (onClickModal) onClickModal(event, newBodyRequest);
	};

	const handleOnClickThumbtack = (event: MouseEvent<HTMLElement>, offer: Offer) => {

		const isPinned = offer?.states?.includes('pinned') ? true : false;

		let stateUpdate: string[] = [
		];

		if (offer?.states?.length) stateUpdate.push(...offer.states);

		if (isPinned) {
			stateUpdate = offer?.states.filter(state => state !== 'pinned');
		} else {
			stateUpdate.push('pinned');
		}

		const newBodyRequest = {
			ids: [
				offer?.id
			],
			commercialization_step : commercializationStep,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setDemandGlobalState(stateUpdate, offer.id);
					break;
			}
		});
	};

	const handleOnClickBookmarked = (event: MouseEvent<HTMLElement>, offer: Offer) => {
		let stateUpdate: string[] = [
		];

		if (offer?.states?.length) stateUpdate.push(...offer.states);

		if (isBookmarked) {
			stateUpdate = offer.states.filter(state => state !== 'bookmarked');
			setIsBookmarked(!isBookmarked);
		} else {
			stateUpdate.unshift('bookmarked');
			setIsBookmarked(!isBookmarked);
		}

		const newBodyRequest = {
			ids: [
				offer.id
			],
			commercialization_step : commercializationStep,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setDemandGlobalState(stateUpdate, offer.id);
					break;
			}
		});
	};

	const handleOnClickPlanMyVisit = (event: MouseEvent<HTMLElement>, offer: Offer) => {
		event.stopPropagation();
		getContacts(demandGlobalState.id, offer.id).then(responseParsed => {
			const newBodyRequest = {
				ids: [
					offer.id
				],
				address: offer.building?.address?.full,
				name: 'plan_my_visit',
				contacts: new Collection(responseParsed.payload, Contact),
				images: offer?.images?.collection[0]?.url,
				demandId: demandGlobalState.id,
				states: offer.states,
				visit: offer.visit
			};

			if (onClickModal) onClickModal(event, newBodyRequest as BodyRequest);
		}).catch(error => {
			console.error('Error:', error);
		});
	};

	const handleButtonWithDropdownClick = (event: MouseEvent<HTMLElement>, offer: Offer) => {
		event.stopPropagation();
		event.preventDefault();
		const initialBookmarked = offer?.states?.includes('bookmarked') ? true : false;
		setIsBookmarked(initialBookmarked);
	};

	const offerRowActions = (offer: Offer) => {
		const visitButton = {
			badgeTheme: null as EnumStatusTheme,
			iconLeft: 'calendar-plus',
			tooltipText: 'plan_my_visit',
		};
		if (offer?.states?.includes('visit_planned')) {
			visitButton.iconLeft = 'calendar-rotate';
			visitButton.tooltipText = 'update_visit';
		} else if (offer?.states?.includes('visit_expired')) {
			visitButton.iconLeft = 'calendar-question';
			visitButton.tooltipText = 'confirm_visit';
			visitButton.badgeTheme = EnumStatusTheme.ERROR;
		}
		if (commercializationStep === 'matched') {
			return (
				<div className={styles.action}>
					<ButtonWithDropdown
						className={styles.button}
						corners={EnumButtonCorners.Square}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={true}
						iconLeft='ellipsis-stroke'
						iconStyle={EnumFontStyle.LIGHT}
						isOpenAfterClick={true}
						items={[
							{
								iconLeft: 'star',
								iconStyle: isBookmarked? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
								label: t('format.capitalize', {
									text: isBookmarked? t('general.action.bookmarked_off') : t('general.action.bookmarked_on')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickBookmarked(event, offer);
								},
							},
							{
								disabled: documentTemplates?.collection?.length > 0 ? false : true,
								iconLeft: 'arrow-to-bottom',
								label: t('format.capitalize', {
									text: t('general.action.download_offer')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickModalDownload(event, offer);
								},
								iconStyle: EnumFontStyle.LIGHT,
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.modify')
								}),
								iconLeft: 'edit',
								iconStyle: EnumFontStyle.LIGHT,
								href: `${APP_CONF_VARS.sfLegacyPath}/offer/${offer.id}/update`,
								target: EnumTargetLink.BLANK,
							},
						] as DropdownItemProps[]}
						name='more_actions'
						size={EnumButtonSize.SMALL}
						style={{
							right: '120%',
							top: '0%',
						}}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.more_actions')
						})}
						onClick={event => {
							handleButtonWithDropdownClick(event, offer);
						}}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft='share-arrow'
						iconStyle={EnumFontStyle.LIGHT}
						name='proposed'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.proposed')
						})}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								ids: [
									offer.id
								],
								commercialization_step: 'proposed',
							});
						}}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft='trash-alt'
						iconStyle={EnumFontStyle.LIGHT}
						name='ignored'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.ignored')
						})}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								ids: [
									offer.id
								],
								commercialization_step: 'ignored',
							});
						}}
					/>
				</div>
			);
		}
		if (commercializationStep === 'proposed') {
			return (
				<div className={styles.action}>
					<ButtonWithDropdown
						className={styles.button}
						corners={EnumButtonCorners.Square}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={true}
						iconLeft='ellipsis-stroke'
						iconStyle={EnumFontStyle.LIGHT}
						isOpenAfterClick={true}
						items={[
							{
								iconLeft: 'star',
								iconStyle: isBookmarked? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
								label: t('format.capitalize', {
									text: isBookmarked? t('general.action.bookmarked_off') : t('general.action.bookmarked_on')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickBookmarked(event, offer);
								},
							},
							{
								disabled: documentTemplates?.collection?.length > 0 ? false : true,
								iconLeft: 'arrow-to-bottom',
								label: t('format.capitalize', {
									text: t('general.action.download_offer')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickModalDownload(event, offer);
								},
								iconStyle: EnumFontStyle.LIGHT,
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.ignored')
								}),
								iconLeft: 'trash-alt',
								iconStyle: EnumFontStyle.LIGHT,
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickCommercializationStep(event, {
										ids: [
											offer.id
										],
										commercialization_step: 'ignored',
									});
								}
							},
							{
								disabled: flags?.hasCRM ? true : false,
								label: t('format.capitalize', {
									text: t('general.action.applied')
								}),
								iconLeft: 'folder',
								iconStyle: EnumFontStyle.LIGHT,
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickCommercializationStep(event, {
										ids: [
											offer.id
										],
										commercialization_step: 'applied',
									});
								}
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.modify')
								}),
								iconLeft: 'edit',
								iconStyle: EnumFontStyle.LIGHT,
								href: `${APP_CONF_VARS.sfLegacyPath}/offer/${offer.id}/update`,
							},
						]}
						name='more_actions'
						size={EnumButtonSize.SMALL}
						style={{
							right: '120%',
							top: '0%',
						}}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.more_actions')
						})}
						onClick={event => {
							handleButtonWithDropdownClick(event, offer);
						}}
					/>
					<Button
						badgeTheme={visitButton.badgeTheme}
						className={styles.button}
						corners={EnumButtonCorners.Square}
						disabled={flags?.hasCRM ? true : false}
						hasBoxShadow={true}
						iconLeft={visitButton.iconLeft}
						iconStyle={EnumFontStyle.LIGHT}
						name={'plan_my_visit'}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t(`general.action.${visitButton.tooltipText}`)
						})}
						onClick={event => {
							handleOnClickPlanMyVisit(event, offer);
						}}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft='times'
						iconStyle={EnumFontStyle.LIGHT}
						name='refused'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.refused')
						})}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								ids: [
									offer.id
								],
								commercialization_step: 'refused',
							});
						}}
					/>
				</div>
			);
		}
		if (commercializationStep === 'applied') {
			return (
				<div className={styles.action}>
					<ButtonWithDropdown
						className={styles.button}
						corners={EnumButtonCorners.Square}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={true}
						iconLeft='ellipsis-stroke'
						iconStyle={EnumFontStyle.LIGHT}
						isOpenAfterClick={true}
						items={[
							{
								iconLeft: 'star',
								iconStyle: isBookmarked? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
								label: t('format.capitalize', {
									text: isBookmarked? t('general.action.bookmarked_off') : t('general.action.bookmarked_on')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickBookmarked(event, offer);
								},
							},
							{
								disabled: documentTemplates?.collection?.length > 0 ? false : true,
								iconLeft: 'arrow-to-bottom',
								label: t('format.capitalize', {
									text: t('general.action.download_offer')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickModalDownload(event, offer);
								},
								iconStyle: EnumFontStyle.LIGHT,
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.cancel_apply')
								}),
								iconLeft: 'arrow-back',
								iconStyle: EnumFontStyle.LIGHT,
								onClick: (event: MouseEvent<HTMLElement>) => {
									handleOnClickCommercializationStep(event, {
										ids: [
											offer.id
										],
										commercialization_step: 'proposed',
									});
								}
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.modify')
								}),
								iconLeft: 'edit',
								iconStyle: EnumFontStyle.LIGHT,
								href: `${APP_CONF_VARS.sfLegacyPath}/offer/${offer.id}/update`,
							},
						]}
						name='more_actions'
						size={EnumButtonSize.SMALL}
						style={{
							right: '120%',
							top: '0%',
						}}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.more_actions')
						})}
						onClick={event => {
							handleButtonWithDropdownClick(event, offer);
						}}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft='folder-grid'
						iconStyle={EnumFontStyle.LIGHT}
						name='apply_progress'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.apply_progress')
						})}
						onClick={event => {
							onClickModal(event, {
								ids: [
									offer.id
								],
								name: 'apply_progress',
								states: offer.states,
							});
						}}
					/>
					<Button
						badgeTheme={visitButton.badgeTheme}
						className={styles.button}
						corners={EnumButtonCorners.Square}
						disabled={flags?.hasCRM ? true : false}
						hasBoxShadow={true}
						iconLeft={visitButton.iconLeft}
						iconStyle={EnumFontStyle.LIGHT}
						name='plan_my_visit'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t(`general.action.${visitButton.tooltipText}`)
						})}
						onClick={event => {
							handleOnClickPlanMyVisit(event, offer);
						}}
					/>
				</div>
			);
		}
		if (commercializationStep === 'refused' || commercializationStep === 'ignored') {
			return (
				<div className={styles.action}>
					<ButtonWithDropdown
						className={styles.button}
						corners={EnumButtonCorners.Square}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={true}
						iconLeft='ellipsis-stroke'
						iconStyle={EnumFontStyle.LIGHT}
						isOpenAfterClick={true}
						items={[
							{
								iconLeft: 'star',
								iconStyle: isBookmarked? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
								label: t('format.capitalize', {
									text: isBookmarked? t('general.action.bookmarked_off') : t('general.action.bookmarked_on')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => handleOnClickBookmarked(event, offer),
							},
							{
								disabled: documentTemplates?.collection?.length > 0 ? false : true,
								iconLeft: 'arrow-to-bottom',
								label: t('format.capitalize', {
									text: t('general.action.download_offer')
								}),
								name: 'download_template',
								onClick: (event: MouseEvent<HTMLElement>) => handleOnClickModalDownload(event, offer),
								iconStyle: EnumFontStyle.LIGHT,
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.modify')
								}),
								iconLeft: 'edit',
								iconStyle: EnumFontStyle.LIGHT,
								href: `${APP_CONF_VARS.sfLegacyPath}/offer/${offer.id}/update`,
							},
						]}
						name='more_actions'
						size={EnumButtonSize.SMALL}
						style={{
							right: '120%',
							top: '0%',
						}}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.more_actions')
						})}
						onClick={event => {
							handleButtonWithDropdownClick(event, offer);
						}}

					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft='share-arrow'
						iconStyle={EnumFontStyle.LIGHT}
						name='proposed'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						tooltipText={t('format.capitalize', {
							text: t('general.action.proposed')
						})}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								ids: [
									offer.id
								],
								commercialization_step: 'proposed',
							});
						}}
					/>
				</div>
			);
		}
	};

	const handleOnChangePagination = (event: MouseEvent<HTMLElement>) => {
		const target = event.target as HTMLElement;
		const content = target.closest(`.${styles.section__overflow}`);
		content.scrollTop = 0;
	};

	const multipleAndDetailActions = (offer?: Offer) => {
		const offerIds = offer ? [
			offer.id
		] : offerSelectedIds;
		let actionsList = null;

		const visitButton = {
			badgeTheme: null as EnumStatusTheme,
			iconLeft: 'calendar-plus',
			tooltipText: 'plan_my_visit',
		};
		if (offer?.states?.includes('visit_planned')) {
			visitButton.iconLeft = 'calendar-rotate';
			visitButton.tooltipText = 'update_visit';
		} else if (offer?.states?.includes('visit_expired')) {
			visitButton.iconLeft = 'calendar-question';
			visitButton.tooltipText = 'confirm_visit';
			visitButton.badgeTheme = EnumStatusTheme.ERROR;
		}
		switch (commercializationStep) {
			case EnumSteps.MATCHED: {
				actionsList = (
					<>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'share-arrow'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined :t('format.capitalize', {
								text: t('general.action.proposed')
							})}
							name='proposed'
							size={EnumButtonSize.SMALL}
							theme={offer ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.proposed')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'proposed',
									ids: offerIds,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined : t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='ignored'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.ignored')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									ids: offerIds,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.PROPOSED: {
				actionsList = (
					<>
						{offer ? (
							<Button
								badgeTheme={visitButton.badgeTheme}
								className={`separator ${styles.button}`}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={visitButton.iconLeft}
								iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
								name='plan_my_visit'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={t('format.capitalize', {
									text: t(`general.action.${visitButton.tooltipText}`)
								})}
								onClick={event => {
									event.stopPropagation();
									const newBodyRequest: BodyRequest = {
										address: offer?.building?.address?.full,
										contacts: offer?.contacts,
										demandId: demandGlobalState.id,
										ids: [
											offer.id
										],
										images: offer?.images?.collection[0]?.url,
										name: 'plan_my_visit',
										states: offer?.states,
										visit: offer?.visit,
									};

									getContacts(demandGlobalState.id, offer.id).then((responseParsed) => {
										newBodyRequest.contacts = new Collection(responseParsed.payload, Contact);
										onClickModal(event, newBodyRequest);
									});
								}}
							/>
						) : undefined}
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							disabled={flags?.hasCRM ? true : false}
							hasBoxShadow={true}
							iconLeft={'folder'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined : t('format.capitalize', {
								text: t('general.action.applied')
							})}
							name='applied'
							size={EnumButtonSize.SMALL}
							theme={offer ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.applied')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'applied',
									ids: offerIds,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'times'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined : t('format.capitalize', {
								text: t('general.action.refused')
							})}
							name='refused'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.refused')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'refused',
									ids: offerIds,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined : t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='ignored'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.ignored')
							}) : undefined}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									ids: offerIds,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.APPLIED: {
				actionsList = (
					<>
						{offer ? (
							<Button
								badgeTheme={visitButton.badgeTheme}
								className={`separator ${styles.button}`}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={visitButton.iconLeft}
								iconStyle={EnumFontStyle.LIGHT}
								name='plan_my_visit'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={t('format.capitalize', {
									text: t(`general.action.${visitButton.tooltipText}`)
								})}
								onClick={event => {
									event.stopPropagation();
									const newBodyRequest: BodyRequest = {
										address: offer?.building?.address?.full,
										contacts: offer?.contacts,
										demandId: demandGlobalState.id,
										ids: [
											offer.id
										],
										images: offer?.images?.collection[0]?.url,
										name: 'plan_my_visit',
										states: offer?.states,
										visit: offer?.visit,
									};

									getContacts(demandGlobalState.id, offer.id).then((responseParsed) => {
										newBodyRequest.contacts = new Collection(responseParsed.payload, Contact);
										onClickModal(event, newBodyRequest);
									});
								}}
							/>
						) : undefined}
						{offer ? (
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								disabled={flags?.hasCRM ? true : false}
								hasBoxShadow={true}
								iconLeft={'folder-grid'}
								iconStyle={EnumFontStyle.LIGHT}
								label={offer ? undefined : t('format.capitalize', {
									text: t('general.action.apply_progress')
								})}
								name='apply_progress'
								size={EnumButtonSize.SMALL}
								theme={EnumTheme.SECONDARY}
								tooltipText={t('format.capitalize', {
									text: t('general.action.apply_progress')
								})}
								onClick={event => {
									onClickModal(event, {
										ids: [
											offer.id
										],
										name: 'apply_progress',
										states: offer.states,
									});
								}}
							/>
						) : undefined}
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							disabled={flags?.hasCRM ? true : false}
							hasBoxShadow={true}
							iconLeft={'arrow-back'}
							iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
							label={offer ? undefined : t('format.capitalize', {
								text: t('general.action.cancel_apply')
							})}
							name='cancel_apply'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							tooltipText={offer ? t('format.capitalize', {
								text: t('general.action.cancel_apply')
							}) : null}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'proposed',
									ids: offerIds,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.IGNORED:
			case EnumSteps.REFUSED: {
				actionsList = (
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft={'share-arrow'}
						iconStyle={offer ? EnumFontStyle.LIGHT : EnumFontStyle.REGULAR}
						label={offer ? undefined : t('format.capitalize', {
							text: t('general.action.proposed')
						})}
						name='proposed'
						size={EnumButtonSize.SMALL}
						theme={offer ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
						tooltipText={offer ? t('format.capitalize', {
							text: t('general.action.proposed')
						}) : undefined}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								commercialization_step: 'proposed',
								ids: offerIds,
							});
						}}
					/>
				);
				break;
			}
		}
		return actionsList;
	};

	const configurationActionBar = {
		type: t('module.offer.name_other'),
		actions: <div className={styles.action_list}>{multipleAndDetailActions(currentOffer)}</div>,
		commercializationStep: commercializationStep,
		demandId: demandGlobalState.id,
	};

	const formatedData = demandGlobalState?.offers?.collection?.map((offer: OfferJson) => {
		return dataUtils.flattenObject(offer);
	});

	const offersListElement = isContentLoaded.demand && isContentLoaded.offers ? (
		<Table
			actionBarClassName={styles.action_bar__container}
			className={styles.table}
			columnFilters={columnFilters}
			columns={columnTableConf(isContentLoaded.canSelect)}
			configurationActionBar={configurationActionBar}
			customNoResult={noResultElement}
			data={formatedData}
			filterFns={(row) => includesSome(row, columnFilters)}
			globalFilter={globalFilter}
			identifier='table_demand_manage'
			options={{
				hasHeader: isDesktopResolution,
				// actions: tableOptionsActions,
				canSelect: isContentLoaded.canSelect,
				pagination: {
					nbItemsPerPage: 10,
					onChange: (event) => {
						handleOnChangePagination(event);
					}
				},
				topChildren: tableTop,
			}}
			reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				if (props !== null) {
					const filteredOfferJson = demandGlobalState?.offers?.collection?.filter((offer: OfferJson) => offer.id === props.original.id);

					const offer = props.original ? new Offer(filteredOfferJson[0] as OfferJson) : undefined;

					return offer ? (
						<PrivateCard
							{...props}
							actionsList={props.isSelected === null && showActions ? offerRowActions(offer) : undefined}
							className={styles.table__row}
							configuration={configurationActionBar}
							isDesktopResolution={isDesktopResolution}
							language={actualLanguage}
							offer={offer}
							viewType='table'
							onClick={(event: MouseEvent<HTMLElement>) => {
								onClickOffer(event, offer.id);
							}}
							onClickContact={handleGetContacts}
							onClickLot={handleGetLots}
							onClickModal={onClickModal}
							onClickThumbtack={(event: MouseEvent<HTMLElement>) => {
								handleOnClickThumbtack(event, (offer as Offer));
							}}
						/>
					) : null;
				}
			}}
			setComponentSelectedIds={setOfferSelectedIds}
			setReinitSelection={setReinitSelection}
			theme='column'
		/>
	) : <ContentLoaderOffers />;

	const [
		mapSectionIsVisibleState,
		setMapSectionIsVisibleState
	] = useState(false);

	const announcementDetail = (
		<PanelDetails
			className={styles.panel_details}
			configurationActionBar={showActions ? configurationActionBar : undefined}
			data={currentOffer}
			isContentLoaded={isContentLoaded}
			isDesktopResolution={isDesktopResolution}
			isEventHistoryLoaded={isEventHistoryLoaded}
			isPrivate={true}
			viewType='table'
			onClickClose={handleOnClosePanelDetail}
			onClickCommercializationStep={handleOnClickCommercializationStep}
			onClickModal={onClickModal}
		/>
	);

	const handleOnClickPreview = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenPreviewModal(true);
	};

	const handleOnClickShare= (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModalShare(true);
	};

	const bottomnav = (
		<div className={styles.bottomnav}>
			<Button
				className={styles.active_map}
				corners={EnumButtonCorners.Square}
				iconRight={mapSectionIsVisibleState ? 'list-ul' : 'map-marker-alt'}
				label={t('format.capitalize', {
					text: t(`general.action.${mapSectionIsVisibleState ? 'view_list' : 'view_map'}`)
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				onClick={event => {
					event.preventDefault();
					setMapSectionIsVisibleState(!mapSectionIsVisibleState);
				}}
			/>
			<div className={styles.preview_share}>
				<Button
					corners={EnumButtonCorners.Square}
					disabled={isPreviewDisabled}
					iconLeft={'eye'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					onClick={handleOnClickPreview}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					disabled={isPreviewDisabled}
					iconLeft={'user-arrow'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.PRIMARY}
					onClick={handleOnClickShare}
				/>
			</div>
		</div>
	);

	const statusMsgsElement = statusMsgs.length ? (
		<div
			className={styles.status_banner_container}
			data-status-msg-wrapper
		>
			{statusMsgs.map((statusBanner: StatusBannerProps, index: number) => {
				return (
					<StatusBanner
						buttons={statusBanner.buttons}
						className={styles.status_banner}
						isClosable={statusBanner.isClosable}
						key={index}
						message={statusBanner.message}
						theme={statusBanner.theme as StatusBannerProps['theme']}
					/>
				);
			})}
		</div>
	) : null;

	return (
		<>
			{isOpenPreviewModal ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenPreviewModal}
					size={ModalSize.FULL}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_title')
					})}
					onClose={() => {
						setIsOpenPreviewModal(false);
					}}
				>
					<ModalFull>
						<iframe
							src={previewPublicUrlDemand}
						></iframe>
					</ModalFull>
				</Modal>
			) : undefined}
			{isOpenModalShare ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenModalShare}
					size={ModalSize.DEFAULT}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_share_title')
					})}
					onClose={() => {
						setIsOpenModalShare(false);
					}}
				>
					<ModalShare
						demand={demandGlobalState}
						publicUrlDemand={publicUrlDemand}
					/>
				</Modal>
			) : undefined}
			<div className={`${styles.section} ${styles.section__left}`}>
				<div className={`${styles.section} ${styles.section__overflow}`}>
					{statusMsgsElement}
					{offersListElement}
					{currentOfferId && currentOffer ? announcementDetail : null}
					{bottomnav}
				</div>
			</div>
		</>
	);
};

export default withTranslation()(TableView);
