import * as React from 'react';
import {
	ChangeEvent,
	FocusEvent,
	MouseEvent,
	RefObject,
	useRef,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// MODULES
import formUtils from '@modules/formUtils';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import LabelInput from '@components/form/input-label';
import {
	InputPropsCommon,
} from '@components/form/input';

// STYLING
import styles from './textarea.module.scss';

type registerHTMLTextarea = Partial<{
	name?: string;
	ref?: RefObject<HTMLTextAreaElement>;
	pattern?: {
		value?: RegExp | string;
		message?: string;
	};
	rules?: {
		ref?: RefObject<HTMLTextAreaElement>;
		required?: {
			message?: string;
			value?: boolean;
		};
		pattern?: {
			message?: string;
			value?: string | RegExp;
		};
		onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
		onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
		onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
	};
}>;

interface MethodsProps {
	register?: (name?: string, rules?: object) => registerHTMLTextarea;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	setError?: (name?: string) => void;
	clearErrors?: (name?: string) => void;
}

interface TextareaProps extends InputPropsCommon {
	children?: string;
	'data-check-value'?: string;
	initialtype?: string;
	innerRef?: RefObject<HTMLTextAreaElement>;
	methods?: MethodsProps;
	onClickIconRight?: (event: MouseEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>, newState: object) => void;
	readOnly?: boolean;
	registerRules?: registerHTMLTextarea['rules'];
	rows?: number;
	toggled?: boolean;
}

const Textarea = ({
	autoComplete,
	autoFocus,
	className,
	customError,
	defaultValue,
	'data-testid': dataTestid,
	disabled,
	hasBorder = true,
	hasShadow = false,
	id,
	invalid,
	innerRef,
	label,
	methods,
	name,
	onBlur,
	onChange,
	pattern,
	placeholder,
	readOnly,
	required,
	rows = 3,
	tabIndex = 0,
	validationType,
	...otherProps
}: TextareaProps): JSX.Element => {
	let register = null;
	const { t } = useTranslation();
	const isControled = methods?.register;
	const registerRules = {
	} as TextareaProps['registerRules'];

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: customError ? customError : t('general.form.input.error.default')
			};
		}

		register = methods.register(name, registerRules);
	}

	const hookRef: RefObject<HTMLTextAreaElement> = useRef();
	const localRef: RefObject<HTMLTextAreaElement> = innerRef || hookRef;

	const initialState: TextareaProps = {
		...otherProps,
		dirty: false,
		disabled: disabled,
		id,
		name,
		invalid: invalid,
		required: required,
		toggled: false,
		error: {
			message: methods?.formState?.errors[name]
		},
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const ref = useRef();

	function handleOnBlur(event: FocusEvent<HTMLTextAreaElement>) {
		const newCssClasses = cssClasses.filter(cssClass => cssClass !== styles.focused);
		setCssClasses([
			...newCssClasses
		]);
		if (onBlur) onBlur(event);
	}

	function getErrorMsg(name: string) {

		const errorMsg = t('general.form.input.error.default');
		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.setError(name);
		}

		return errorMsg;
	}

	function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
		const textareaHTML = event.currentTarget.value;
		const isInvalid = state.required ? !formUtils.isInputValid(event.currentTarget) : false;

		let newState: TextareaProps = {
			...state,
			error: {
			},
			defaultValue: textareaHTML,
		};

		if (isInvalid && !invalid) {
			newState = {
				...newState,
				error: {
					message: getErrorMsg(name)
				},
			};
		}

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.clearErrors(newState.name);
		}

		setState(newState);

		if (onChange) onChange(event, newState);
	}

	const classes = [
		styles.textarea,
		`input__${id}`,
	];

	if (className) classes.push(className);
	if (hasBorder) classes.push(styles.border);
	if (hasShadow) classes.push(styles.shadow);
	if (readOnly) classes.push(styles.read_only);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	const handleOnFocus = () => {
		setCssClasses([
			...cssClasses,
			styles.focused
		]);
	};

	let errorMsgText: string = null;
	if (typeof state.error === 'object' && state.error?.message) {
		errorMsgText = state.error.message;
	} else if (typeof invalid === 'object' && invalid?.message) {
		errorMsgText = customError ? customError : invalid?.message;
	}

	useEffect(() => {
		const updatedClasses = [
			...classes
		];
		if (errorMsgText) updatedClasses.push(styles.invalid);
		setCssClasses(updatedClasses);
	}, [
		errorMsgText
	]);

	const errorMsgElement = state.error.message && !state.defaultValue || invalid ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{errorMsgText}
		</div>
	) : null;

	const ariaInvalid = errorMsgElement ? 'true' : 'false';

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			ref={ref}
		>
			{label ? (
				<LabelInput
					data-testid={`${dataTestid}-label`}
					id={id}
					textEllipsis={true}
				>
					{decodeURI(t(label))}
				</LabelInput>
			) : null
			}

			<textarea
				aria-invalid={ariaInvalid}
				autoCapitalize="sentences"
				autoComplete={autoComplete}
				autoFocus={autoFocus}
				data-check-value={otherProps['data-check-value']}
				data-testid={`${dataTestid}-textarea`}
				defaultValue={defaultValue}
				disabled={disabled}
				id={id}
				name={name}
				pattern={pattern || null}
				placeholder={placeholder ? t(placeholder) : ''}
				readOnly={readOnly}
				value={state.defaultValue}
				{...register}
				ref={localRef}
				rows={rows}
				tabIndex={tabIndex}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				onFocus={handleOnFocus}
			>{state.defaultValue}</textarea>
			{
				state.error?.message || invalid ?
					errorMsgElement
					:
					null
			}
		</div >
	);
};

Textarea.displayName = EnumComponentType.TEXTAREA;

export {
	Textarea as default,
	TextareaProps,
};
