import * as React from 'react';

import {
	JSXElementConstructor,
} from 'react';

import InputRadio, {
	InputRadioProps,
} from '@components/form/input-radio-group/input-radio';

import {
	ComponentWithRadioButtonProps,
} from '@components/form/input-radio-group/component-with-radio-button';

//STYLES
import styles from './with-radio-button.module.scss';

function withRadioButton(WrappedComponent: JSXElementConstructor<ComponentWithRadioButtonProps>) {

	return ({
		className,
		checked,
		disabled,
		id,
		innerRef,
		name,
		'data-testid': dataTestid,
		onChange,
		value,
		...otherProps
	}: InputRadioProps): JSX.Element => {

		const cssClasses = [
			styles.with_radio_button
		];

		const cssClassesComponent = [
			styles.component,
		];

		if (className) cssClasses.push(className);

		return (
			<div
				className={`${cssClasses.join(' ')}`}
				data-testid={dataTestid}
			>
				<div
					data-testid={`${dataTestid}-radio-button`}
				>
					<InputRadio
						checked={checked}
						data-testid={`${dataTestid}-radio-group`}
						disabled={disabled}
						id={id}
						innerRef={innerRef}
						name={name}
						value={value}
						onChange={onChange}
					/>
				</div>
				<WrappedComponent
					className={cssClassesComponent.join(' ')}
					id={id}
					name={name}
					{...otherProps}
				/>

			</div>
		);

	};
}

export { withRadioButton as default, };

