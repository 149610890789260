// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qTA76h3f{display:flex}.qTA76h3f .Uw1yv0BD{margin-bottom:1.42857143rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"with_radio_button": `qTA76h3f`,
	"component": `Uw1yv0BD`
};
export default ___CSS_LOADER_EXPORT___;
