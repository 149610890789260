import {
	useEffect,
	useRef,
} from 'react';
import {
	useDispatch,
} from 'react-redux';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// STORES
import {
	userLogout,
} from '@stores/_slices/user';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';

// MODULES
import utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

function PageLogout() {
	const dispatch = useDispatch();

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			dispatch(clearToastMsgs());
			dispatch(clearStatusMsgs());
			fetch(utils.getURL(FETCHES.private.logout), {
				...APP_CONF_VARS.request.default,
			}).then((resp) => {
				return resp.json();
			}).then(() => {
				dispatch(userLogout());
				dispatch(clearDemands());
			}).catch(err => {
				console.warn('ERROR : ', err);
			});
		}
	}, [
	]);
}

export default (PageLogout as () => null);
