import * as React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Button from '@components/button';

// ASSETS
import locationImage from '@assets/images/lpdipro/location.jpg';

// STYLES
import styles from './location.module.scss';
import Image from '@components/image';

const Location = () => {
	const {t} = useTranslation();
	const {
		offerState,
		// setOfferState,
		isDesktopResolution,
	}: PageOfferContextProps = useOutletContext();

	// eslint-disable-next-line
	const onSubmitSuccess = (data: any) => {
		console.log(data);
	};

	// eslint-disable-next-line
	const onSubmitError = (data: any) => {
		console.log(data);
	};

	return offerState ? (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.entry_offer.step')} 1/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				<div className={styles.title}>
					{t('format.capitalize', {
						text: t('page.entry_offer.where_is_your_offer')
					})}
				</div>
				<Form
					className={styles.form}
					noValidate={true}
					onError={onSubmitError}
					onSuccess={onSubmitSuccess}
				>
					<InputText
						autoComplete='street-address'
						className={styles.input}
						name='add_street_address'
						placeholder={t('format.capitalize', {
							text: t('general.form.input.type.add_street_address.placeholder')
						})}
						required={true}
						type={EnumInputType.EMAIL}
					/>
					<InputText
						autoComplete='street-address'
						className={styles.input}
						name='add_city_address'
						placeholder={t('format.capitalize', {
							text: t('general.form.input.type.add_city_address.placeholder')
						})}
						required={true}
						type={EnumInputType.EMAIL}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						iconLeft='search'
						type={EnumButtonType.SUBMIT}
					/>
				</Form>
				<ValidationForm offerState={offerState} />
			</div>
			{isDesktopResolution ? (
				<Image
					alt='placeholder'
					className={styles.image}
					src={locationImage}
				/>
			) : null}
		</div>

	) : null;
};

export default withTranslation()(Location);
