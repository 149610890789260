import * as React from 'react';
import {
	ChangeEvent,
	useRef,
	useState,
} from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

//COMPONENTS
import Icon from '@components/icon';
import {
	InputTextProps,
} from '@components/form/input-text';
import InputLabel from '@components/form/input-label';

// STYLES
import styles from './input-time-picker.module.scss';

interface InputTimePickerProps extends Omit<InputTextProps, 'defaultValue' | 'value'> {
	defaultValue?: moment.Moment;
    format?: string;
    hasIcon?: boolean;
	minuteStep?: number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
    showHour?: boolean;
    showMinute?: boolean;
	value?: moment.Moment;
}

const InputTimePicker = ({
	className,
	'data-testid': dataTestid,
	defaultValue = moment(),
	disabled,
	format = 'HH:mm',
	hasBorder = true,
	hasShadow = false,
	hasIcon = true,
	id,
	label,
	name,
	placement = 'bottomLeft',
	showHour = true,
	showMinute = true,
	minuteStep = 5,
	size,
	value,
}: InputTimePickerProps): JSX.Element => {
	const inputRef = useRef(null);

	const [
		newValue,
		setNewValue
	] = useState(value ? value : defaultValue);

	const [
		open,
		setOpen
	] = useState(false);

	/* istanbul ignore next */
	const handleOnChange = (value: moment.Moment) => {
		const minutesChanged = value.minutes() !== newValue.minutes();
		setNewValue(value);
		minutesChanged ? setOpen(false) : undefined;
	};

	const IconElement = hasIcon ? (
		<Icon
			data-testid={`${dataTestid}-icon`}
			fontStyle={EnumFontStyle.LIGHT}
			name="clock"
		/>
	) : null;

	const labelElement = label ? (
		<InputLabel data-testid={`${dataTestid}-label`}>{label}</InputLabel>
	) : null;

	const classes = [
		styles.time_picker_custom
	];

	const classesTimePicker = [
	];

	if (className) classes.push(className);
	if (size) classesTimePicker.push(`${'size__' + size}`);
	if (hasBorder) classesTimePicker.push('border');
	if (hasShadow) classesTimePicker.push('shadow');

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{labelElement}
			<TimePicker
				className={classesTimePicker.join(' ')}
				defaultValue={value ? value : moment()}
				disabled={disabled}
				format={format}
				id={id}
				inputIcon={IconElement}
				minuteStep={minuteStep}
				name={name}
				open={open}
				placement={placement}
				popupClassName={classes.join(' ')}
				ref={inputRef}
				showHour={showHour}
				showMinute={showMinute}
				showSecond={false}
				value={newValue}
				onChange={handleOnChange}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			/>
		</div>
	);

};

InputTimePicker.displayName = EnumComponentType.INPUT_TIME_PICKER;

export {
	InputTimePicker as default,
	InputTimePickerProps,
};
