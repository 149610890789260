import React, {
	ReactNode,
} from 'react';

// ROUTES
import PATHS from '@routes/paths';

// MODULES
import i18n from '@modules/i18n';
import dataUtils from '@modules/data-utils';
import Permissions from '@modules/permissions';

// DATA
import {
	businessTools,
	publicData,
	searchEngines,
	studies,
} from '@static_data/permissions-groups.data';
import {
	businessToolsRights,
	commercializationRights,
	managementRights,
} from '@static_data/permissions-rights.data';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// TYPES
import {
	ObservatoryReport,
	UserJson,
} from '@@types/User';

// COMPONENTS
import LinkToLogout from '@components/link-to-logout';

const getNavigationData = (userData: UserJson) => {
	const modulePermissions = new Permissions({
		payload: userData?.modules || {
		}
	});

	const observatoryReportsPermissions = userData?.modules?.observatories?.permissions ? Object.keys(userData.modules.observatories.permissions).filter(permission => {
		if (userData?.modules?.observatories?.permissions[`${permission}`]) {
			return permission;
		}
	}) : [
	];

	const publicObservatoryReports = userData?.observatory_reports?.filter((observatoryReport: ObservatoryReport) => {
		if (!observatoryReport?.is_private) {
			return observatoryReportsPermissions.includes(observatoryReport.permission_name);
		}
	});
	const privateObservatoryReports = userData?.observatory_reports?.filter((observatoryReport: ObservatoryReport) => {
		if (observatoryReport?.is_private) {
			return observatoryReportsPermissions.includes(observatoryReport.permission_name);
		}
	});
	const observatoryReportsLink = (observatoriesReport: ObservatoryReport[] = [
	]) => {
		return observatoriesReport?.length ? observatoriesReport.map((observatoryReport: ObservatoryReport) => {
			return {
				href: PATHS.OBSERVATORY.REPORT.replace(':reportid', `${observatoryReport.id}`),
				hasAccess: true,
				label: `${i18n.t('format.capitalize', {
					text: observatoryReport.name
				})}`,
				target: EnumTargetLink.SELF,
			};
		}) : [
		];
	};

	console.log('modulePermissions', modulePermissions.can('default_offer_display_private', 'offer') && modulePermissions.can('offer_form_v2', 'offer'));

	const data = [
		{
			label: '',
			items: [
				[
					{
						text_icon: userData.firstname ? userData.firstname.split('')[0].toUpperCase() : userData.username ? userData.username.split('')[0].toUpperCase() : 'Ω',
						label: `${i18n.t('format.capitalize', {
							text: i18n.t('navigation.profile')
						})}`,
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								{
									href: PATHS.USER.PROFILE.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('profile_user.my_account')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									href: PATHS.USER.HISTORY.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('profile_user.my_history')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'my_alerts'),
									href: PATHS.USER.LISTS.DYNAMICS.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('profile_user.my_alerts')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'my_lists'),
									href: PATHS.USER.LISTS.STATICS.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('profile_user.my_lists')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									content: <LinkToLogout>{i18n.t('general.action.logout') as ReactNode}</LinkToLogout>
								}
							]
						]
					},
				],
			],
			visible: {
				desktop: false,
				mobile: true
			}
		},
		{
			separator: true,
			visible: {
				desktop: false,
				mobile: true
			}
		},
		{
			hasAccess: modulePermissions.can('read', publicData),
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('navigation.public_data')
			})}`,
			fontWeight: EnumFontStyle.SEMIBOLD,
			items: [
				[
					{
						hasAccess: modulePermissions.can('read', searchEngines),
						icon: 'sliders-h-square-search',
						label: `${i18n.t('format.capitalize', {
							text: i18n.t('navigation.search_engines')
						})}`,
						items: [
							[
								{
									hasAccess: modulePermissions.can('read', 'owner'),
									href: PATHS.SEARCH.OWNER.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.owner.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'occupier'),
									href: PATHS.SEARCH.OCCUPIER.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.occupier.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'offer'),
									href: PATHS.SEARCH.OFFER.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.offer.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'transaction'),
									href: PATHS.SEARCH.TRANSACTION.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.transaction.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'building'),
									href: PATHS.SEARCH.BUILDING.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.building.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'land'),
									href: PATHS.SEARCH.LAND.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.land.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'project'),
									href: PATHS.SEARCH.PROJECT.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.project.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read', 'permit'),
									href: PATHS.SEARCH.PERMIT.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.permit.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
							],
						]
					},
					{
						hasAccess: modulePermissions.can('read', studies),
						icon: 'chart-pie',
						label: `${i18n.t('format.capitalize', {
							text: i18n.t('module.study.name_other')
						})}`,
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								{
									hasAccess: modulePermissions.can('read', 'analyze'),
									href: PATHS.SEARCH.ANALYZE.LEGACY,
									label: `${i18n.t('format.capitalize', {
										text: i18n.t('module.analyze.name_other')
									})}`,
									target: EnumTargetLink.BLANK,
								},
								...observatoryReportsLink(publicObservatoryReports)
							]
						],
					},
					{
						hasAccess: observatoryReportsLink(privateObservatoryReports).length ? true : false,
						icon: 'analytics',
						label: `${i18n.t('format.capitalize', {
							text: i18n.t('module.observatory.name_other')
						})}`,
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								...observatoryReportsLink(privateObservatoryReports)
							]
						],
					},
				],
			],
		},
		{
			separator: true,
		},
		{
			hasAccess: modulePermissions.can(businessToolsRights, businessTools),
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('navigation.business_tools')
			})}`,

			items: [
				[
					{
						hasAccess: modulePermissions.can(commercializationRights, 'commercialization'),
						icon: 'sale-second',
						label: `${i18n.t('format.capitalize', {
							text: i18n.t('module.commercialization.name')
						})}`,
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								{
									hasAccess: modulePermissions.can('commercialization_module', 'commercialization') || modulePermissions.can('mls_packages', 'commercialization'),
									href: modulePermissions.can('commercialization_module', 'commercialization') ? PATHS.DEMANDS._ROOT : PATHS.SEARCH.MY_DEMAND.LEGACY,
									label: `${i18n.t('general.action.view_my_other', {
										'item': i18n.t('module.my_demand.name_other'),
									})}${userData.modules?.commercialization?.activity?.count_my_demand > 0 ? ` (${userData.modules.commercialization.activity.count_my_demand})` : ''}`,
									target: modulePermissions.can('commercialization_module', 'commercialization') ? EnumTargetLink.SELF : EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('commercialization_module', 'commercialization') || modulePermissions.can('mls_packages', 'commercialization'),
									href: PATHS.DEMANDS.LEGACY,
									label: i18n.t('general.action.add_one_female', {
										'item': i18n.t('module.demand.name')
									}),
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('commercialization_module', 'commercialization') || modulePermissions.can('mls_packages', 'commercialization'),
									href: PATHS.SEARCH.MY_OFFER.LEGACY,
									label: `${i18n.t('general.action.view_my_other', {
										'item': i18n.t('module.my_offer.name_other'),
									})}${userData.modules?.commercialization?.activity?.count_my_offer > 0 ? ` (${userData.modules.commercialization.activity.count_my_offer})` : ''}`,
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('create_my_offer', 'commercialization'),
									href:modulePermissions.can('default_offer_display_private', 'offer') && modulePermissions.can('offer_form_v2', 'offer') ? PATHS.OFFERS.NEW : PATHS.SEARCH.OFFER_ADD.LEGACY,
									label: i18n.t('general.action.add_one_female', {
										'item': i18n.t('module.my_offer.name')
									}),
									target: modulePermissions.can('default_offer_display_private', 'offer') && modulePermissions.can('offer_form_v2', 'offer') ? EnumTargetLink.SELF : EnumTargetLink.BLANK
								}
							],
						],
					},
					{
						hasAccess: modulePermissions.can(managementRights, 'management'),
						icon: 'steering-wheel',
						label: i18n.t('format.capitalize', {
							text : i18n.t('module.management.name')
						}),
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								{
									hasAccess: modulePermissions.can('read_asset', 'management'),
									href: PATHS.SEARCH.ASSET_LIST.LEGACY,
									label: i18n.t('general.action.view_my_other', {
										'item': i18n.t('module.asset.name_other'),
									}),
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('create_asset', 'management'),
									href: PATHS.SEARCH.ASSET_ADD.LEGACY,
									label: i18n.t('general.action.add_one', {
										'item': i18n.t('module.asset.name')
									}),
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read_lease', 'management'),
									href: PATHS.SEARCH.LEASE_LIST.LEGACY,
									label: i18n.t('general.action.view_my_other', {
										'item': i18n.t('module.lease.name_other'),
									}),
									target: EnumTargetLink.BLANK,
								},
								{
									hasAccess: modulePermissions.can('read_site', 'management'),
									href: PATHS.SEARCH.SITE_LIST.LEGACY,
									label: i18n.t('general.action.view_my_other', {
										'item': i18n.t('module.site.name_other')
									}),
									target: EnumTargetLink.BLANK,
								},
							],
						],
					},
				],
			],
		},
		{
			separator: true,
			visible: {
				desktop: false,
				mobile: true
			}
		},
		{
			label: '',
			items: [
				[
					{
						icon: 'info-circle',
						label: i18n.t('format.capitalize', {
							text: i18n.t('general.informations')
						}),
						fontWeight: EnumFontStyle.SEMIBOLD,
						items: [
							[
								{
									href: PATHS.ZENDESK.HELP_CENTER.LEGACY,
									label: i18n.t('format.capitalize', {
										text: i18n.t('general.action.help_center')
									}),
								},
								{
									href: PATHS.TRAINING_WORKSHOP.LEGACY,
									label: i18n.t('format.capitalize', {
										text: i18n.t('general.action.training_workshop')
									}),
								}
							]
						]
					}
				],
			],
			visible: {
				desktop: false,
				mobile: true
			}
		}
	];

	return dataUtils.filteredData(data, [
	]);
};

export default getNavigationData;
