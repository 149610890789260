import React, {
	Suspense,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	Outlet,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

import {
	EnumThemeLink,
} from '@enums/link.enum';

// ROUTES
import PATHS from '@routes/paths';

// STORES
import {
	appNavClose,
	appNavSet,
	appNavToggle,
} from '@stores/_slices/app';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';

// STATIC DATA
import getNavigationData from '@static_data/navigation.data';
import getProfileData from '@static_data/profile.data';
import EVENTS from '@static_data/events.data';

// MODULES
import eventEmitter from '@modules/eventEmitter';

// COMPONENTS
import Button, {
} from '@components/button';
import ButtonWithDropdown from '@components/button-with-dropdown';
import Header from '@components/header';
import {
	ItemDataProps,
} from '@components/navigation/navigation-item';
import Link from '@components/link';
import Loader from '@components/loader';
import Logo from '@components/logo';
import Navigation from '@components/navigation';

// STYLING
import styles from './PageAppPrivate.module.scss';
import GetObservatoryReportListError from '@exceptions/GetObservatoryReportListError';
import {
	userUpdateOservatoryReports,
} from '@stores/_slices/user';
import utils from '@modules/utils';
import FETCHES from '@routes/fetches';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

function PageAppPrivate() {
	const dispatch = useDispatch(); // Dispatches an action. This is the only way to trigger a state change.

	const { t } = useTranslation();

	// Get mobile and desktop nav state
	const navIsOpenGlobalState = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);

	// Get user state
	const userState = useSelector((state: ReducerInstance) => state.user.instance);
	const app = useSelector((state: ReducerInstance) => state.app.instance);

	// Get device state
	const device = useSelector((state: ReducerInstance) => state.device.instance);

	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const profileItems = getProfileData(userState);

	const isIframe = window.self !== window.top;

	const handleOnClickToggleNav = () => {
		dispatch(appNavToggle(device.display));
	};

	useEffect(() => {
		const isMobileNav = [
			'mobile',
			'tablet'
		].includes(device.display);
		if (isMobileNav) {
			// Navigation is always closed when display changes
			dispatch(appNavClose(device.display));
		} else {
			// Navigation is set to the last known state in store when display changes
			dispatch(appNavSet(device.display));
		}
	}, [
		device.display
	]);

	const [
		observatoriesIsLoaded,
		setObservatoriesIsLoaded
	] = useState(false);

	const getObservatoryReports = () => {
		fetch(utils.getURL(FETCHES.private.observatory.reports), {
			...APP_CONF_VARS.request.default,
			language: app.localization.actualLanguage
		} as RequestInit).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(userUpdateOservatoryReports(responseParsed.payload));
					break;
			}
			setObservatoriesIsLoaded(true);
		}).catch(error => {
			throw new GetObservatoryReportListError(error);
		});
	};

	const [
		navigationItems,
		setNavigationItems
	] = useState(null);

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			getObservatoryReports();
		}
	}, [
	]);

	useEffect(() => {
		setNavigationItems(getNavigationData(userState));
	}, [
		observatoriesIsLoaded
	]);

	const cssNavClasses = [
		styles.private_navigation,
		'animation'
	];

	const [
		navClassNames,
		setNavClassNames,
	] = useState(cssNavClasses);

	useEffect(() => {
		if (navIsOpenGlobalState) {
			const cssClasses = [
				...navClassNames,
				styles.private_navigation__open
			];
			isDesktopResolution ? undefined : cssClasses.push('animation__ltr');
			setNavClassNames(cssClasses);
		} else {
			setNavClassNames(cssNavClasses);
		}
	}, [
		navIsOpenGlobalState
	]);

	const headerElement = (
		<Header className={styles.header}>
			<>
				<div className={`${styles.region} ${styles.region__left}`}>
					<span className={`${styles.item} ${styles.triggernav}`}>
						<Button
							hasBoxShadow={false}
							iconLeft={navIsOpenGlobalState ? 'times' : 'bars'}
							iconStyle={EnumFontStyle.LIGHT}
							theme={EnumTheme.NAKED}
							onClick={handleOnClickToggleNav}
						/>
					</span>
				</div>
				<div className={`${styles.region}`}>
					<span className={`${styles.item} ${styles.logo}`}>
						<Link
							href={PATHS._ROOT}
							theme={EnumThemeLink.NAKED}
							onClick={() => {
								isDesktopResolution ? undefined : dispatch(appNavClose(device.display));
							}}
						>
							<Logo
								full={true}
							/>
						</Link>
					</span>
				</div>
				<div className={`${styles.region} ${styles.region__right}`}>
					<span className={`${styles.item} ${styles.notif}`}>
						<Button
							className="js-beamer"
							hasBoxShadow={false}
							iconClassName={`${styles.notif__icon}`}
							iconLeft='bell'
							iconStyle={EnumFontStyle.LIGHT}
							theme={EnumTheme.NAKED}
						/>
					</span>
					<span className={`${styles.item} ${styles.training}`}>
						<Button
							hasBoxShadow={false}
							href={PATHS.TRAINING_WORKSHOP.LEGACY}
							iconLeft='graduation-cap'
							iconStyle={EnumFontStyle.LIGHT}
							theme={EnumTheme.NAKED}
							onClick={() => {
								// Event custom dispatched to be used with GTM
								eventEmitter.dispatch(EVENTS.USER_TRAINING_WORKSHOP_CLICK);
							}}
						/>
					</span>
					<span className={`${styles.item} ${styles.help}`}>
						<Button
							hasBoxShadow={false}
							href={PATHS.ZENDESK.HELP_CENTER.LEGACY}
							iconLeft='question-circle'
							iconStyle={EnumFontStyle.LIGHT}
							theme={EnumTheme.NAKED}
							onClick={() => {
								// Event custom dispatched to be used with GTM
								eventEmitter.dispatch(EVENTS.USER_HELP_CENTER_CLICK);
							}}
						/>
					</span>
					<span className={`${styles.item} ${styles.profile}`}>
						<ButtonWithDropdown
							corners={EnumButtonCorners.Round}
							hasBoxShadow={false}
							items={profileItems}
							label={t('format.capitalize', {
								text: userState.firstname ? userState.firstname.split('')[0].toUpperCase() : userState.username ? userState.username.split('')[0].toUpperCase() : 'Ω'
							})}
							style={{
								right: '3%',
								top: 'calc(100% + 15px)'
							}}
							theme={EnumTheme.PRIMARY}
						/>
					</span>
				</div>
			</>
		</Header>
	);

	const navavigationElement = navigationItems ? (
		<Navigation
			className={navClassNames.join(' ')}
			deviceDisplay={device.display}
			isOpen={navIsOpenGlobalState}
			items={navigationItems as ItemDataProps[]}
			onClickItem={() => {
				isDesktopResolution ? undefined : dispatch(appNavClose(device.display));
			}}
			onClickToggle={() => {
				dispatch(appNavToggle(device.display));
			}}
		/>
	) : null;

	const [
		cssPageClasses,
		setCssPageClasses,
	] = useState([
		styles.PageAppPrivate,
	]);

	useEffect(() => {
		if (navIsOpenGlobalState && !isDesktopResolution) {
			const cssClasses = [
				styles.PageAppPrivate,
				styles.PageAppPrivate__navisopen
			];
			setCssPageClasses(cssClasses);
		} else {
			setCssPageClasses([
				styles.PageAppPrivate,
			]);
		}
	}, [
		navIsOpenGlobalState
	]);

	return (
		<Suspense fallback={<Loader />}>
			<div className={cssPageClasses.join(' ')}>
				{isIframe ? null : headerElement}
				<div className={styles.content}>
					{isIframe ? null : navavigationElement}
					{/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</div>
			</div>
		</Suspense>
	);
}
export default withTranslation()(PageAppPrivate);
