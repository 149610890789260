import React from 'react';
import PATHS from '@routes/paths';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useNavigate,
} from 'react-router-dom';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// STORES
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// COMPONENTS
import Button, {
} from '@components/button';
import Form, {
	ResponseParsedProps,
} from '@components/form';
import StatusBanner from '@components/status-banner';

// LAYOUTS
import Page from '@layouts/Pages/Page';

// ROUTES
import FETCHES from '@routes/fetches';

// STYLES
import styles from './step-password-forgot.module.scss';
import InputText from '@components/form/input-text';
import {
	EnumInputType,
} from '@enums/form.enum';

function PagePasswordForgot() {
	const { t } = useTranslation();
	const dispatch = useDispatch(); // Dispatches an action. This is the only way to trigger a state change.
	const navigate = useNavigate();
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);

	const handleOnError = (response: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		// Manage error message
		if (response.statusText) {
			dispatch(addStatusMsg({
				message: t([
					`status.${response.statusText}`,
					'status.default'
				]),
				theme: EnumStatusTheme.ERROR,
			}));
		}
	};

	const handleOnSuccess = (responseParsed: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		switch (responseParsed.status) {
			case 200:
				// Navigate to success password recovery
				navigate(PATHS.LOGIN.PASSWORD_EMAIL_SENT);
				break;
			default:
				dispatch(addStatusMsg({
					message: t([
						`status.${responseParsed.statusText}`,
						'status.default'
					]),
					theme: EnumStatusTheme.ERROR,
				}));
		}
	};
	return (
		<Page
			className={`${styles.step} wrapper__small`}
			pageName='login'
			step='password_forgot'
		>
			{statusMsgs.length ? (
				<div
					className={styles.statusMsgWrapper}
					data-status-msg-wrapper
				>
					{statusMsgs.map((statusBanner, index) => {
						return (
							<StatusBanner
								key={index}
								message={statusBanner.message}
								theme={statusBanner.theme}
							/>
						);
					})}
				</div>
			) : ''}
			<div className={styles.main}>
				<Form
					action={FETCHES.public.authentification.reset_password_request}
					noValidate={true}
					showDevTool={true}
					onError={(responseParsed) => {
						handleOnError(responseParsed as ResponseParsedProps);
					}}
					onSuccess={(responseParsed) => {
						handleOnSuccess(responseParsed as ResponseParsedProps);
					}}
				>
					<InputText
						autoComplete="username"
						autoFocus={true}
						id="reset_request_password__username"
						label={t('general.form.input.type.username.label')}
						name="reset_request_password[_username]"
						placeholder="general.form.input.type.username.placeholder"
						required={true}
						type={EnumInputType.EMAIL}
					/>
					<div className={styles.button_group}>
						<Button
							corners={EnumButtonCorners.Square}
							label={t('general.action.cancel')}
							name="cancel"
							theme={EnumTheme.TERTIARY}
							to={PATHS.LOGIN._ROOT}
							type={EnumButtonType.BUTTON}
						/>
						<Button
							corners={EnumButtonCorners.Square}
							label={t('format.capitalize', {
								text: t('general.action.validate')
							})}
							name="submit"
							type={EnumButtonType.SUBMIT}
						/>
					</div>
				</Form>
			</div>
		</Page>
	);
}

export default withTranslation()(PagePasswordForgot);
