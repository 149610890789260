import * as React from 'react';
import {
	ChangeEvent,
	MouseEvent,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// MODULES
import formUtils from '@modules/formUtils';

// COMPONENTS
import {
	MessageProps,
} from '@components/form/input';
import InputText, {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-password.module.scss';

const InputPassword = ({
	autoComplete,
	className,
	customError,
	defaultValue,
	iconRight,
	id,
	invalid,
	methods,
	name,
	onChange,
	onClickIconRight,
	required,
	type = EnumInputType.PASSWORD,
	typeCustom,
	...otherProps
}: InputTextProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	const typesWithDefaultIcons = [
		'password'
	];

	// input type with icon mapping
	const iconMapping = {
		password: 'eye',
		password_toggle: 'eye-slash',
	};

	const initialState: InputTextProps = {
		...otherProps,
		defaultValue,
		dirty: false,
		id,
		name,
		initialtype: typeCustom || type,
		invalid,
		required,
		toggled: false,
		type,
		error: methods?.formState?.errors[name] as MessageProps,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	function getErrorMsg({
		initialtype,
		value,
		name
	}: InputTextProps) {
		let errorMsg = state?.error?.message || null;

		// change error msg from type only when field has value
		if (value?.length && otherProps['data-check-value']?.length) {
			errorMsg = t('general.form.input.error.not_equal', {
				'%type%': t('format.lowercase', {
					'text': t(`general.form.input.type.${initialtype}.label_other`)
				})
			}) as string;
		} else if (value?.length && autoComplete === 'new-password') {
			errorMsg = t('general.form.input.error.password_format') as string;
		} else if (value?.length && (i18n.exists(`general.form.input.error.${initialtype}`))) {
			errorMsg = (t(`general.form.input.error.${initialtype}`)) as string;
		}

		errorMsg = (errorMsg ? errorMsg : t('general.form.input.error.default')) as string;
		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.setError(name);
		}

		return errorMsg;
	}

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const inputHTML = event.currentTarget;
		const isDirty = state.dirty === false || (state?.value !== state.initialValue) as boolean;
		const isInvalid = state.required ? !formUtils.isInputValid(inputHTML) : false;

		let newState: InputTextProps = {
			...state,
			dirty: isDirty,
			error: null,
			required: required,
			defaultValue: inputHTML.value,
			value: inputHTML.value,
		};

		if (isInvalid) {
			newState = {
				...newState,
				error: {
					message: getErrorMsg(newState)
				},
			};
		}

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.clearErrors(newState.name);
		}

		setState(newState);

		if (onChange) onChange(event, newState);
	}

	const handleClickIcon = (event: MouseEvent<HTMLElement>) => {
		const newState = {
			...state,
			type: state.initialtype,
			dirty: true,
			toggled: !state.toggled,
		};

		if (state.initialtype === EnumInputType.PASSWORD && !state.toggled && state.type === EnumInputType.PASSWORD) {
			newState.type = EnumInputType.TEXT;
		}

		if (onClickIconRight) onClickIconRight(event);
		setState(newState);
	};

	const getTypeIcon = (isToggled: boolean) => {
		let iconResult = null;
		const iconTypeExistsInMapping = Object.keys(iconMapping).includes(state.initialtype);

		if (state.iconRightToggled && state.initialtype !== EnumInputType.PASSWORD) {
			iconResult = isToggled ? state.iconRightToggled : iconRight;
		} else if (typesWithDefaultIcons.includes(state.initialtype) && iconTypeExistsInMapping) {
			iconResult = isToggled ? iconMapping['password_toggle'] : iconMapping['password'];
		} else {
			iconResult = iconRight;
		}

		return iconResult;
	};

	const classes = [
		styles.input_password,
		`input__${id}`,
	];

	if (className) classes.push(className);

	return (

		<InputText
			{...otherProps}
			autoComplete={autoComplete}
			className={classes.join(' ')}
			customError={customError}
			defaultValue={state.defaultValue}
			iconRight={getTypeIcon(state.toggled)}
			id={id}
			invalid={invalid || state.error}
			methods={methods}
			name={name}
			required={required}
			type={state.type}
			onChange={handleOnChange}
			onClickIconRight={handleClickIcon}
		/>

	);
};

InputPassword.displayName = EnumComponentType.INPUT_PASSWORD;

export {InputPassword as default,};
