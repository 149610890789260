import * as React from 'react';
import {
	Children,
	cloneElement,
	isValidElement,
	ReactElement,
	ReactNode,
} from 'react';

//STYLES
import styles from './tab.module.scss';

interface TabProps {
	isActive?: boolean;
	children?: ReactNode;
	className?: string;
	'data-testid'?: string;
	disabled?: boolean;
	isSuccessful?: boolean;
}

const applyClassesToChildren = (children: ReactNode, childClasses: string[], dataTestid: string | undefined): ReactNode => {
	return Children.map(children, (child, index) => {
		if (isValidElement(child)) {
			const existingClasses = child.props.className ? child.props.className.split(' ') : [
			];
			const combinedClasses = [
				...existingClasses,
				...childClasses
			].join(' ');

			const dataChildTestid = dataTestid ? `${dataTestid}-child-${index}` : undefined;

			const updatedChild = cloneElement(child as ReactElement, {
				className: combinedClasses,
				'data-testid': dataChildTestid,
			});

			if (child.props.children) {
				return React.cloneElement(updatedChild, {
					children: applyClassesToChildren(updatedChild.props.children, childClasses, dataChildTestid),
				});
			}
			return updatedChild;
		}
		return child;
	});
};

const Tab = ({
	isActive = false,
	children,
	className,
	'data-testid': dataTestid,
	disabled,
	isSuccessful = false,
}: TabProps): JSX.Element => {
	const classes = [
		styles.tab
	];

	if (isSuccessful) classes.push(styles.tab__successful);
	if (isActive) classes.push(styles.tab__active);
	if (disabled) classes.push(styles.tab__disabled);
	if (className) classes.push(className);

	const childClasses = [
		styles.child
	];
	if (isSuccessful) childClasses.push(styles.child__successful);
	if (isActive) childClasses.push(styles.child__active);
	if (disabled) childClasses.push(styles.child__disabled);

	const updatedChildren = applyClassesToChildren(children, childClasses, dataTestid);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{updatedChildren}
		</div>
	);
};

export {
	Tab as default,
	TabProps,
};
