// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MC8EQWNo{background-color:var(--color-background-default);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3);font-size:0.85714286rem;font-weight:600;height:-webkit-max-content;height:max-content;line-height:1em;width:-webkit-max-content;width:max-content;z-index:2}.MC8EQWNo:before{height:0.35714286rem !important}.MC8EQWNo:after{background-color:var(--color-background-default) !important}.MC8EQWNo span{text-align:left !important}.core-styles-module_closing__sGnxF{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTooltip": `MC8EQWNo`
};
export default ___CSS_LOADER_EXPORT___;
