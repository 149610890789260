import React, {
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import {
	Trans,
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	addToastMsg,
} from '@stores/_slices/toast_msgs';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	userNotifyOtherUser,
} from '@stores/_slices/user';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import connectionLimitExceededTableConf from './table.unique_connection_error.conf.json';

// TYPES
import {
	UserJson,
} from '@@types/index';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
	ButtonProps,
} from '@components/button';
import Icon from '@components/icon';
import Image from '@components/image';
import Table from '@components/table';
import Text from '@components/text';

// ASSETS
import img_404 from '@assets/images/lpdipro/illustration_error404.png';

// MODULES
import utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// STYLES
import styles from './PageError.module.scss';

interface originalProps extends ButtonProps {
	original?: {
		notified?: string;
		id?: number;
	};
}

interface rowDataProps {
	desktop?: {
		items?: originalProps[];
		title?: string;
	};
	mobile?: {
		items?: originalProps[];
		title?: string;
	};
}

function PageError() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userState = useSelector((state: ReducerInstance) => state.user.instance);
	const device = useSelector((state: ReducerInstance) => state.device.instance);
	const { code } = useParams();
	const navigate = useNavigate();

	const codeList = [
		'default',
		'404',
		'connection_limit_exceeded',
		'unique_connection_error'
	];

	const statusTextCode = codeList.includes(code) ? code : 'default';

	document.title = `${t('website.brand_name')} - ${t(`page.error.${statusTextCode}.title`)}`;

	const [
		buttonIsLoading,
		setButtonIsLoading
	] = useState(false);

	const backBtnWording = userState.authenticated ? 'general.action.back_to_home' : 'general.action.back_to_login';

	// Function to get table data from state
	const getTableConfDataInitialState = () => {
		return userState.otherUsers?.length ? [
			...userState.otherUsers
		].map((otherUser: UserJson) => {
			const inactivityDate = new Date(otherUser.inactivity.date as number);
			const inactivityDateMinutes = inactivityDate.getMinutes();
			const mobile = (
				<span className={styles.tel}><Icon
					className={styles.phone}
					fontStyle={EnumFontStyle.LIGHT}
					name='phone-alt'
				/> {otherUser.mobile || otherUser.phone ? t('format.tel', {
					val: otherUser.mobile || otherUser.phone
				}) : '???'}</span>
			);
			return {
				'id': otherUser.id,
				'full_name': device.display === 'desktop' ? <Text ellipsis={true}>{otherUser.full_name}</Text> : otherUser.full_name,
				'mobile': mobile,
				'inactivity': `dernière activité il y a ${inactivityDateMinutes === 0 ? 'moins d\'une minute' : `${inactivityDateMinutes} mn`}`,
				'notified': otherUser.notified
			};
		}) : [
		];
	};

	// Local table state
	const [
		tableConfDataState,
		setTableConfDataState
	] = useState(getTableConfDataInitialState());

	// update local table state when global otherUsers state change
	useEffect(() => {
		if (statusTextCode === 'connection_limit_exceeded') {
			setTableConfDataState(getTableConfDataInitialState());
		}
	}, [
		userState.otherUsers
	]);

	let tableElement = null;

	if (statusTextCode === 'connection_limit_exceeded') {
		// Function to config and get request access btn on each table items
		const getRequestAccessBtn = (data: { id?: number }) => {
			const otherUserState = userState?.otherUsers?.length ? userState.otherUsers.filter((otherUser: UserJson) => otherUser.id === data.id)[0] : undefined;
			const handleOnClickAccessRequest = (event: MouseEvent<HTMLSelectElement>) => {
				const target = event.currentTarget;
				setButtonIsLoading(true);
				fetch(utils.getURL(FETCHES.public.authentification.please_disconnect), {
					...APP_CONF_VARS.request.default,
					method: 'POST',
					body: JSON.stringify({
						id: data.id,
					})
				})
					.then((resp) => {
						target.disabled = false;
						return resp.json();
					})
					.then((responseParsed) => {
						setButtonIsLoading(false);
						if ([
							200
						].includes(responseParsed.status)) {
							otherUserState ? dispatch(userNotifyOtherUser(otherUserState)) : (
								navigate(PATHS.HOMEPAGE)
							);
						} else {
							dispatch(addToastMsg({
								message: t('format.capitalize', {
									text: t('status.default'),
								}),
								theme: EnumStatusTheme.ERROR,
							}));
						}
					}).catch(err => {
						console.warn('ERROR : ', err);
					});
			};

			return (
				<Button
					corners={EnumButtonCorners.Square}
					disabled={buttonIsLoading}
					key='send'
					label={t(`page.error.${statusTextCode}.access_request`)}
					loader={true}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					onClick={handleOnClickAccessRequest}
				/>
			);
		};

		// Request succes wording to replace button
		const accessAskedTxt = (
			<span
				key='send'
			>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconRight='check'
					key='send'
					label={t(`page.error.${statusTextCode}.access_requested`)}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
				/>
			</span>
		);

		// Insert new data settings in global table settings
		connectionLimitExceededTableConf.data = tableConfDataState;

		tableElement = (
			<Table
				className={styles.table}
				{...connectionLimitExceededTableConf}
				actions={{
					desktop: {
						items: [
							(rowData: originalProps) => {
								const btn = getRequestAccessBtn(rowData.original);
								return rowData.original.notified ? accessAskedTxt : btn;
							}
						]
					},
					mobile: {
						items: [
							(rowData: originalProps) => {
								const btn = getRequestAccessBtn(rowData.original);
								return rowData.original.notified ? accessAskedTxt : btn;
							}
						]
					}
				} as rowDataProps}
				identifier='error_page'
			/>
		);
	}

	const content = (
		<div className={styles.body}>
			<div className={styles[`code_${statusTextCode}__content`]}>
				<h1 className={styles.title}>
					{t('format.capitalize', {
						text: t(`page.error.${statusTextCode}.title`)
					})}
				</h1>
				<p
					className={styles.subligne}
				>
					<Trans
						components={{
							br: <br />,
						}}
						i18nKey={`page.error.${statusTextCode}.content`}
					/>
				</p>
				{statusTextCode === 'connection_limit_exceeded' ? (
					<h3 className={styles.subtitle}>
						{t('format.capitalize', {
							text: t(`page.error.${statusTextCode}.connected_users`)
						})}
					</h3>
				) : null}
				{tableElement}
				<Button
					className={styles.action_button}
					label={t(backBtnWording)}
					to={PATHS._ROOT}
				/>
			</div>
			{statusTextCode === '404' ? (
				<Image
					alt=''
					className={styles.image}
					src={img_404}
				/>
			) : null}
		</div>
	);

	return (
		<div
			className={styles.PageError}
		>
			{content}
		</div>
	);
}

export default withTranslation()(PageError);
