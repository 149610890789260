import * as React from 'react';
import {
	useState,
	RefObject,
	useEffect,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';

// ENUMS
import {
	EnumThemeLink,
} from '@enums/link.enum';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// COMPONENTS
import Link from '@components/link';
import Sticky from '@components/sticky';
import TabList from '@components/tab-list';
import Tab from '@components/tab-list/tab';

// STYLES
import styles from './header.module.scss';
import Icon from '@components/icon';

interface HeaderProps {
	isDesktopResolution: boolean;
	contentEntryOfferRef: RefObject<HTMLDivElement>;
}

const Header = ({
	isDesktopResolution,
	contentEntryOfferRef,
}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);

	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: t('page.entry_offer.title')
				})}
			</h1>
		</div>
	);

	const tabListElement = (
		<TabList className={styles.step_list}>
			<Tab
				isActive={true}
			>
				<Link
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.location')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.infos')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.contacts')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.areas')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.media')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.services')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.terms')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
			<Tab
				disabled={true}
			>
				<Link
					href={undefined}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.entry_offer.steps.summary')
					})}
					<Icon
						name='circle'
					/>
				</Link>
			</Tab>
		</TabList>
	);

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter
	]);

	useEffect(() => {
		const baseClasses = [
			styles.filter
		];
		if (isSticky) {
			baseClasses.push(styles.filter__sticky);
			if (navIsOpen && isDesktopResolution) {
				baseClasses.push(styles.filter__sticky__navisopen);
			}
		}
		setStickyCssClasses(baseClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	return (
		<>
			<div className={styles.header}>
				{titleElement}
				<Sticky
					className={stickyCssClasses.join(' ')}
					isDesktopResolution={true}
					isSticky={isSticky}
					scrollContainerRef={contentEntryOfferRef}
					setIsSticky={setIsSticky}
				>
					{tabListElement}
				</Sticky>
			</div>
		</>
	);
};

export default withTranslation()(Header);
