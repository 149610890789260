import * as React from 'react';
import {
	ReactNode,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import PATHS from '@routes/paths';

// STORES
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	DeviceDisplays,
} from '@stores/_slices/device';

// ROUTES
import FETCHES from '@routes/fetches';

// TYPES
import {
	ContactJson,
} from '@@types/Contact';
import {
	DocumentTemplatesProps,
} from '@@types/Collection';
import {
	User,
} from '@@types/User';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
} from '@components/button';
import InputSelect, {
	DropdownSize,
} from '@components/form/input-select';
import Paragraph from '@components/paragraph';

// MODULES
import utils from '@modules/utils';

// STYLES
import styles from './ModalDocumentsTemplates.module.scss';

export enum ActionType {
	PREVIEW = 'preview',
	DOWLOAD = 'download',
}

interface ModalDocumentsTemplatesProps {
    brokers?: ContactJson[];
	children?: ReactNode;
	documentTemplates?: DocumentTemplatesProps[];
	offerId?: number;
	title?: string;
}

const ModalDocumentsTemplates = ({
	brokers,
	documentTemplates,
	offerId,
	title
}: ModalDocumentsTemplatesProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const optionBrokers = [
	] as object[];

	const [
		selectDocId,
		setSelectDocId
	] = useState(documentTemplates[0]?.id as number);

	const [
		selectBrokerId,
		setSelectBrokerId
	] = useState(brokers[0]?.id);

	const [
		previewUrlIsLoading,
		setPreviewUrlIsLoading
	] = useState(false);

	const [
		downloadUrlIsLoading,
		setDownloadUrlIsLoading
	] = useState(false);

	const generateUrl = async (actionType: ActionType) => {
		dispatch(clearToastMsgs());
		actionType === ActionType.PREVIEW ? setPreviewUrlIsLoading(true) : setDownloadUrlIsLoading(true);
		await fetch(utils.getURL(actionType === ActionType.PREVIEW ? FETCHES.private.generate_preview : FETCHES.private.generate_download, {
			':templateid': selectDocId.toString(),
		}), {
			...APP_CONF_VARS.request.default,
			method: 'POST',
			body: JSON.stringify({
				offer_id: offerId,
				contact_user_id: selectBrokerId
			})
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					actionType === ActionType.PREVIEW ? setPreviewUrlIsLoading(false) : setDownloadUrlIsLoading(false);
					if (actionType === ActionType.PREVIEW) {
						const pdfUrl = encodeURIComponent(responseParsed.payload.link);
						const apryseURL = `${APP_CONF_VARS.publicPath}${PATHS.OFFERS.EXPORT_PDF}?url=:url`;
						const formatedUrl = utils.getURL(apryseURL, {
							':offerid': offerId.toString(),
							':url': pdfUrl,
						});
						window.open(formatedUrl, '_blank');
					} else if (actionType === ActionType.DOWLOAD) {
						const link = document.createElement('a');
						link.href = responseParsed.payload.link;
						link.style.display = 'none';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(err => {
			console.warn('ERROR : ', err);
		}).finally(() => {
			actionType === ActionType.PREVIEW ? setPreviewUrlIsLoading(false) : setDownloadUrlIsLoading(false);
		});
	};

	const fullNameDefault = new User(brokers[0]).full_name;

	brokers?.forEach((broker: ContactJson) => {
		const fullName = new User(broker).full_name;
		const option = {
			label: fullName,
			value: broker.id
		};
		optionBrokers.push(option as object);
	});

	const optionDocuments = [
	] as object[];

	documentTemplates?.forEach((doc: DocumentTemplatesProps) => {
		const option = {
			label: doc.name,
			value: doc.id
		};
		optionDocuments.push(option as object);
	});

	return (
		<div className={styles.default}>
			{title ? <h2 className={styles.title}>{title}</h2> : null}
			<Paragraph
				className={styles.modal_content}
			>
				<h3>{t('format.capitalize', {
					text: t('modals.document_templates.subtitle_description')
				})}</h3>
				{documentTemplates?.length > 1 ? (
					<InputSelect
						className={styles.inputSelect}
						dropdownSize={DropdownSize.FULL}
						hasEllipsis={true}
						name="update_demand_state"
						options={optionDocuments}
						onClickOption={(event) => {
							const selectDocIdValue = Number((event.target as HTMLElement).dataset.selectValue);
							setSelectDocId(selectDocIdValue);
						}}
					/>
				) : (documentTemplates[0] as DocumentTemplatesProps)?.name}

				<h3 className={styles.title_negociator}>{t('format.capitalize', {
					text: t('modals.document_templates.subtitle_negociator')
				})}</h3>

				{brokers.length > 1 ? (
					<InputSelect
						className={styles.inputSelect}
						dropdownSize={DropdownSize.FULL}
						hasEllipsis={true}
						name="update_demand_state"
						options={optionBrokers}
						onClickOption={(event) => {
							const selectBrokerIdValue = Number((event.target as HTMLElement).dataset.selectValue);
							setSelectBrokerId(selectBrokerIdValue);
						}}
					/>
				) : fullNameDefault}

			</Paragraph>
			<div className={styles.footer_buttons} >
				{ device.display === DeviceDisplays.DESKTOP  ? (
					<Button
						corners={EnumButtonCorners.Square}
						disabled={previewUrlIsLoading}
						label={t('format.capitalize', {
							text: t('modals.document_templates.button_preview')
						})}
						loader={true}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						onClick={() => {generateUrl(ActionType.PREVIEW);}}
					/>
				) : null }
				<Button
					corners={EnumButtonCorners.Square}
					disabled={downloadUrlIsLoading}
					label={t('format.capitalize', {
						text: t('modals.document_templates.button_download')
					})}
					loader={true}
					size={EnumButtonSize.SMALL}
					onClick={() => {generateUrl(ActionType.DOWLOAD);}}
				/>
			</div>
		</div>
	);
};

export default ModalDocumentsTemplates;
