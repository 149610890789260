// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QfmzGJuD{font-size:1rem;margin:0;position:relative}.QfmzGJuD label{cursor:pointer;display:inline-flex;flex-direction:row}.QfmzGJuD label:focus .nWre65w_{border-color:var(--color-border-focus)}.QfmzGJuD input{display:none}.BxPVbFDp{margin-left:0.92857143rem}.qJziWnrB{border-radius:0.57142857rem;position:relative}.HfMPv0xU input{padding:0.85714286rem 4rem 0.85714286rem 1.28571429rem}.G8MTXG7y{color:currentcolor}.G8MTXG7y.NzhgTgEv{color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.NzhgTgEv label{background:var(--color-wild-sand);background-color:inherit;border-radius:0.57142857rem;color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5);cursor:not-allowed}.NzhgTgEv label:focus .nWre65w_{border-color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.q6BSWmWU{color:var(--color-status-error);font-size:0.85714286rem;margin-top:0.35714286rem;text-align:left}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_radio": `QfmzGJuD`,
	"radio_button": `nWre65w_`,
	"input_radio__text": `BxPVbFDp`,
	"input_radio__field_wrapper": `qJziWnrB`,
	"input_radio__icon": `HfMPv0xU`,
	"focused": `G8MTXG7y`,
	"disabled": `NzhgTgEv`,
	"error": `q6BSWmWU`
};
export default ___CSS_LOADER_EXPORT___;
