import * as React from 'react';
import {
	Dispatch,
	FormEvent,
	MouseEvent,
	SetStateAction,
	useState,
} from 'react';
import {
	withTranslation,
	useTranslation,
	Trans,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// TYPES
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// EXCEPTIONS
import SetUpdateDemandStateError from '@exceptions/SetUpdateDemandStateError';

// MODULES
import utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

// STORES
import {
	demandUpdate,
} from '@stores/_slices/demands';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
} from '@components/button';
import InputSelect, {
	DropdownSize,
} from '@components/form/input-select';
import LabelStatus from '@components/label-status';

// STYLES
import styles from './ModalUpdateDemandState.module.scss';

interface ModalUpdateDemandStateProps {
	demand: DemandJsonPrivate;
	isDesktopResolution?: boolean;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setApplicationModalConfirmation?: Dispatch<SetStateAction<boolean>>;
	onClickUpdateDemandState?: (args: object) => void;
}

function ModalUpdateDemandState({
	demand,
	isDesktopResolution,
	setIsOpenModal,
}: ModalUpdateDemandStateProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [
		selectedIndexState,
		setSelectedIndexState
	] = useState(undefined);

	const optionsElement = [
		{
			label: t('format.capitalize', {
				text: t('status.active')
			}),
			theme: EnumTheme.PRIMARY,
			value: 'active',
		},
		{
			label: t('format.capitalize', {
				text: t('status.pending')
			}),
			theme: EnumStatusTheme.WAIT,
			value: 'pending',
		},
		{
			label: t('format.capitalize', {
				text: t('status.successful')
			}),
			theme: EnumStatusTheme.SUCCESS,
			value: 'successful',
		},
		{
			label: t('format.capitalize', {
				text: t('status.abandoned_female')
			}),
			theme: EnumStatusTheme.ABANDONED,
			value: 'abandoned',
		},
		{
			label: t('format.capitalize', {
				text: t('status.failed')
			}),
			theme: EnumStatusTheme.ERROR,
			value: 'failed',
		},
	];
	const initialIndex = optionsElement.findIndex((option) => option.value === demand?.state);

	const [
		informationState,
		setInformationState
	] = useState(null);

	const [
		isDisabled,
		setIsDisabled
	] = useState(false);

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModal(false);
	});

	const handleOnClickConfirm = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsDisabled(true);
		setIsOpenModal(false);

		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());

		const target = event.target as HTMLElement;
		const selectElement = target.closest('div').querySelector('select');

		const bodyRequest = {
			state: selectElement.value,
		};

		fetch(utils.getURL(FETCHES.private.demands.id, {
			':demandid': `${demand?.id}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify(bodyRequest),
		})
			.then((resp) => {
				return resp.json();
			})
			.then((responseParsed) => {
				//event triggered on page load
				switch (responseParsed.status) {
					case 200:
						dispatch(demandUpdate({
							demand: {
								...demand,
								state: bodyRequest.state,
								settings: {
									notifications: {
										enable_new_matching: bodyRequest.state === 'active'
									}
								}
							},
							demand_id: demand.id,
						}));
						break;
					default: {
						setIsDisabled(false);
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${responseParsed.statusText}`,
									'status.default'
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
					}
				}
			}
			).catch(error => {
				setIsDisabled(false);
				throw new SetUpdateDemandStateError(error);
			});
	};

	const handleOnClickOption = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();

		const target = event.target as HTMLElement;
		const selectElement = target.closest('form').querySelector('select');
		const optionIndexSelected = optionsElement.findIndex((option) => option.value === selectElement.value);

		setSelectedIndexState(optionIndexSelected);
		let informationText = null;

		switch (selectElement.value) {
			case 'active':
				informationText = 'active';
				break;
			case 'pending':
				informationText = 'pending';
				break;
			default:
				informationText = 'default';
		}
		setInformationState(informationText);
	};

	return (
		<form
			onSubmit={event => {
				handleOnClickConfirm(event);
			}}
		>
			<div className={styles.update_demand_state}>
				<h2 className={styles.title}>
					{t('format.capitalize', {
						text: t('modals.update_demand_state.title')
					})}
				</h2>
				<div className={styles.introduction}>
					{t('format.capitalize', {
						text: t('modals.update_demand_state.introduction', {
							'demandId': demand.id
						})
					})}
				</div>
				<InputSelect
					className={styles.select}
					dropdownSize={DropdownSize.FULL}
					hasEllipsis={true}
					name="update_demand_state"
					options={optionsElement}
					selectedIndex={selectedIndexState !== undefined ? selectedIndexState : initialIndex}
					style={isDesktopResolution ? {
						top: 'calc(100% + 6px)',
						left: '0'
					} : {
						bottom: 'calc(100% + 6px)',
						left: '0'
					}}
					onClickOption={handleOnClickOption}
				/>
				{informationState ? (
					<div className={styles.information}>
						<div>
							<Trans
								components={{
									LabelStatus: <LabelStatus
										context='female'
										text={selectedIndexState === null ? null : optionsElement[selectedIndexState].value}
									/>,
								}}
								i18nKey={'modals.update_demand_state.new_state'}
							/>
						</div>
						<div>
							<Trans
								components={{
									br: <br />,
									span: <span />,
								}}
								i18nKey={`modals.update_demand_state.information.${informationState}`}
							/>
						</div>
					</div>
				) : null}
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						disabled={isDisabled}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						disabled={isDisabled}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</form>
	);
}

export default withTranslation()(ModalUpdateDemandState);
