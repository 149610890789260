import utils from '@modules/utils';
import FETCHES from '@routes/fetches';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import GetAnnouncementMatchingAnnouncementDemandsError from '@exceptions/GetAnnouncementMatchingAnnouncementDemandsError';
import GetDemandsMatchingAnnouncementDemandsError from '@exceptions/GetDemandsMatchingAnnouncementDemandsError';
import SetCommercializationStepError from '@exceptions/SetCommercializationStepError';
import {
	BodyRequestMatching,
} from '..';

export const getAnnouncement = async (offerId: number) => {
	return await fetch(utils.getURL(FETCHES.private.offers.id, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetAnnouncementMatchingAnnouncementDemandsError(error);
	});
};

export const getDemands = async (offerId: number, commercializationStep: string) => {
	return await fetch(utils.getURL(FETCHES.private.offers.step, {
		':offerid': `${offerId}`,
		':step': `${commercializationStep}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetDemandsMatchingAnnouncementDemandsError(error);
	});
};

export const setCommercializationStep = async (offerId: number, args: BodyRequestMatching) => {
	return await fetch(utils.getURL(FETCHES.private.offers.matching, {
		':offerid': `${offerId}`
	}), {
		...APP_CONF_VARS.request.default,
		method: 'PATCH',
		body: JSON.stringify(args),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new SetCommercializationStepError(error);
	});
};
