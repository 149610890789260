import React, {
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import {
	useNavigate,
} from 'react-router-dom';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

// STORES
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	userLogin,
	userLogout,
} from '@stores/_slices/user';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// MODULES
import utils from '@modules/utils';

// LAYOUTS
import Page from '@layouts/Pages/Page';

// COMPONENTS
import Button, {
} from '@components/button';
import Form, {
	ResponseParsedProps,
} from '@components/form';
import Link from '@components/link';
import InputText from '@components/form/input-text';
import StatusBanner from '@components/status-banner';

// STYLES
import styles from './step-auth-sms.module.scss';

function StepAuthSMS() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);

	const [
		resendDisabledState,
		setResendDisabledState
	] = useState(false);

	const logout = () => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());

		if (user.auth_step) {
			fetch(utils.getURL(FETCHES.private.logout), {
				...APP_CONF_VARS.request.default,
			}).then(() => {
				dispatch(userLogout());
				dispatch(clearDemands());
				navigate(PATHS.LOGIN._ROOT);
			});
		} else {
			dispatch(userLogout());
			dispatch(clearDemands());
		}
	};

	useEffect(() => {
		if (!user.auth_step) {
			navigate(PATHS.LOGIN._ROOT);
		}
	}, [
	]);

	const handleOnClickCancel = () => {
		logout();
	};

	const handleOnClickResendPwd = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		setResendDisabledState(true);

		fetch(utils.getURL(FETCHES.public.authentification.authentificator_2fa_request), {
			...APP_CONF_VARS.request.default,
		}).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			setResendDisabledState(false);
			switch (responseParsed.status) {
				case 403:
					dispatch(addStatusMsg({
						message: t('status.error_auth_sms_sent_limit_exceed'),
						theme: EnumStatusTheme.ERROR,
					}));
					break;
				default:
					dispatch(addStatusMsg({
						message: t('page.login.step.success_step_2fa_sms.sms_sent_success'),
						theme: EnumStatusTheme.SUCCESS,
					}));
			}
		}).catch(err => {
			setResendDisabledState(false);
			console.warn('ERROR : ', err);
		});

	};

	const handleOnError = (responseParsed: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		if (responseParsed.statusText) {
			// Manage error message
			dispatch(addStatusMsg({
				message: t([
					`status.${responseParsed.statusText}`,
					t('status.default')
				]),
				theme: EnumStatusTheme.ERROR,
			}));
		}
	};

	const handleOnSuccess = (responseParsed: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		dispatch(userLogin(responseParsed.payload.user));
	};

	return (
		<Page
			className={`${styles.step} wrapper__medium`}
			pageName='login'
			step='success_step_2fa_sms'
		>
			{statusMsgs.length ? (
				<div
					className={styles.statusMsgWrapper}
					data-status-msg-wrapper
				>
					{statusMsgs.map((statusBanner, index) => {
						return (
							<StatusBanner
								key={index}
								message={statusBanner.message}
								theme={statusBanner.theme}
							/>
						);
					})}
				</div>
			) : ''}
			<div className={`wrapper__small ${styles.main}`}>
				<Form
					action={FETCHES.public.authentification.authentificator_2fa_code}
					noValidate={true}
					showDevTool={true}
					onError={(responseParsed) => {
						handleOnError(responseParsed as ResponseParsedProps);
					}}
					onSuccess={(responseParsed) => {
						handleOnSuccess(responseParsed as ResponseParsedProps);
					}}
				>
					<InputText
						autoFocus={true}
						id="auth_sms_login_form__auth_code"
						label={t('general.form.input.type.code.label')}
						name="auth_sms_login_form[_auth_code]"
						placeholder="general.form.input.type.code.placeholder"
						required={true}
						type={EnumInputType.NUMBER}
					/>
					<div className={styles.button_group}>
						<Button
							corners={EnumButtonCorners.Square}
							label={t('format.capitalize', {
								text: t('general.action.cancel')
							})}
							name="cancel"
							theme={EnumTheme.TERTIARY}
							to={PATHS.LOGIN._ROOT}
							onClick={handleOnClickCancel}
						/>
						<Button
							corners={EnumButtonCorners.Square}
							label={t('format.capitalize', {
								text: t('general.action.validate')
							})}
							name="submit"
							type={EnumButtonType.SUBMIT}
						/>
					</div>
				</Form>
				<div className={styles.footer}>
					<p>{t('page.login.step.success_step_2fa_sms.content')}</p>
					<Link
						disabled={resendDisabledState}
						theme={EnumThemeLink.PRIMARY}
						onClick={handleOnClickResendPwd}
					>
						{t('page.login.step.success_step_2fa_sms.link_1')}</Link>
				</div>
			</div>
		</Page>
	);
}

export default withTranslation()(StepAuthSMS);
