// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yAjcoDsN{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;height:-webkit-max-content;height:max-content;overflow-y:auto;padding-bottom:9.71428571rem;padding-top:1.42857143rem;width:37.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.yAjcoDsN{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.yAjcoDsN{padding-bottom:7.14285714rem;padding-top:2.85714286rem}}.yAjcoDsN.yTN92aAU{padding:1.42857143rem 1.14285714rem 2.28571429rem 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.yAjcoDsN.yTN92aAU{padding:2.85714286rem}}.yAjcoDsN .jgLIquBr{font-size:1rem;font-weight:600;margin-bottom:0.57142857rem;margin-top:1.42857143rem}.yAjcoDsN .pbLh3LSO,.yAjcoDsN .dxizLvFV{width:100%}.yAjcoDsN .MRiDZhWG{align-items:center;background-color:var(--color-white);border-radius:0;bottom:0;box-shadow:0 0 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);display:flex;flex-direction:column;justify-content:center;left:0;min-height:4.28571429rem;padding:1rem 1.14285714rem;position:absolute;width:100%;z-index:2}@media screen and (min-width: 768Px)and (orientation: landscape){.yAjcoDsN .MRiDZhWG{border-radius:0 0 0.57142857rem 0.57142857rem;flex-direction:row;padding:0 2.85714286rem;justify-content:space-between}}.yAjcoDsN .MRiDZhWG button{margin-bottom:0.57142857rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.yAjcoDsN .MRiDZhWG button{margin-bottom:0;margin-left:0.64285714rem;width:100%}.yAjcoDsN .MRiDZhWG button:first-child{margin-left:0}}.yAjcoDsN .MRiDZhWG button:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visit_confirmation": `yAjcoDsN`,
	"no_action": `yTN92aAU`,
	"title": `jgLIquBr`,
	"textarea_info": `pbLh3LSO`,
	"usercard": `dxizLvFV`,
	"footer_buttons": `MRiDZhWG`
};
export default ___CSS_LOADER_EXPORT___;
