import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	matchRoutes,
	useLocation,
} from 'react-router-dom';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import TagManager from 'react-gtm-module';
import {
	User,
} from '@@types/User';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.lpdipro.conf';
import EVENTS from '@static_data/events.data';

// STORE
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	deviceUpdate,
} from '@stores/_slices/device';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	GlobalUser,
	userLogin,
	userLogout,
	userUpdateSession,
} from '@stores/_slices/user';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// ROUTES
import {
	PRIVATE_ROUTES_GLOBAL,
} from '@routes/index';
import FETCHES from '@routes/fetches';

// MODULES
import Device from '@modules/device';
import eventEmitter from '@modules/eventEmitter';
import utils from '@modules/utils';

// HOOKS
import useThrottledEffect from '@hooks/useTrottledEffect/hook.useTrottledEffect';
import useVisibilityChange from '@hooks/useVisibilityChangeEffect/hook.useVisibilityChangeEffect';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Toast, {
	ToastProps,
} from '@components/toast';
import Loader from '@components/loader';

// LAYOUTS
import AppPrivate from './_private';
import AppPublic from './_public';

// STYLES
import '@styles/styles';
import styles from './lpdipro.module.scss';

interface responseParsedProps {
	payload?: User;
}

interface userFilteredProps {
	zones?: string;
	segment?: string;
	beamer?: {
		filter?: string;
	};
}

function LpdiProApp() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const app = useSelector((state: ReducerInstance) => state.app.instance);
	const toastMsgs = useSelector((state: ReducerInstance) => state.toast_msgs.instances);

	const toastMsgsElement = toastMsgs.length ? (
		<div
			className={styles.toastMsgWrapper}
			data-status-msg-wrapper
		>
			{toastMsgs.map((toastItem: ToastProps, index: number) => {
				return (
					<Toast
						className={`${styles.toastMsgs} animation__disappearing_tooltip`}
						key={index}
						message={toastItem.message}
						theme={toastItem.theme}
					/>
				);
			})}
		</div>
	) : '';

	const isPrivateRoute = matchRoutes(PRIVATE_ROUTES_GLOBAL, location.pathname)?.length ? true : false;

	const [
		isPrivateRouteState,
		setIsPrivateRouteState
	] = useState(isPrivateRoute);

	const [
		appIsLoaded,
		setAppIsLoaded
	] = useState(false);

	const [
		isGTMLoadedState,
		setGTMLoadedState
	] = useState(false);

	const getParams = window.location.search.split(/\?|&/);
	const isDebug = getParams.filter(getParam => {
		const entries = getParam.split('=').length ? getParam.split('=') : [
		];
		const key = entries.length ? entries[0] : '';
		const value = entries.length ? entries[1] : '';
		return key === 'debug' && value == '1';
	}).length;

	// AUTHENTICATION CHECKING ON EVERY ROUTE CHANGE
	const getSessionValid = async() => {
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		await fetch(utils.getURL(FETCHES.public.me), {
			...APP_CONF_VARS.request.default,
		}).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			if (responseParsed.payload?.redirect_url) {
				// REDIRECT ASKED BY BACKEND
				window.location.href = responseParsed.payload.redirect_url;
				return;
			}
			if (responseParsed.status !== 200 && isPrivateRouteState && user.authenticated) {
				// STORING THE PREVIOUS ROUTE BEFORE AUTH
				dispatch(appSetRoute({
					name: 'from',
					url: location.pathname
				}));
				dispatch(userLogout());
				dispatch(clearDemands());
			} else if (!user.authenticated && responseParsed.status === 200) {
				dispatch(userLogin(responseParsed.payload.user));
			}
		}).catch(err => {
			console.warn('ERROR : ', err);
		}).finally(() => {
			setAppIsLoaded(true);
		});
	};

	// AUTHENTICATION CHECKING ON EVERY ROUTE CHANGE
	const getUserAuth = async(callback?: (payload: responseParsedProps) => void) => {

		// Remove every other status banner
		dispatch(clearStatusMsgs());

		// Remove every other toast
		dispatch(clearToastMsgs());

		// check if server session exists on app load
		await fetch(utils.getURL(FETCHES.public.session), {
			...APP_CONF_VARS.request.default,
		}).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			// Everytime 'redirect_url' is present in the payload response, we must redirect.
			// usecase : when the CGU are not accepted yet by the user

			if (responseParsed.status !== 200 && isPrivateRouteState && user.authenticated) {
				// STORING THE PREVIOUS ROUTE BEFORE AUTH
				dispatch(appSetRoute({
					name: 'from',
					url: location.pathname
				}));
			}
			switch (responseParsed.status) {
				case 200:
					dispatch(userUpdateSession());
					if (app.routes.from) {
						dispatch(appSetRoute({
							name: 'from',
							url: null // remove the value if the current one is the same to avoid recursive redirects
						}));
					}
					break;
				default:
					dispatch(userLogout());
					dispatch(clearDemands());
					// Manage error message
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}

			// calling getUser callback if exists. Example : use to clear a setInterval
			if (callback) callback(responseParsed);

		}).catch(err => {
			console.warn('ERROR : ', err);
		});
	};

	const [
		activeEvent,
		setActiveEvent
	] = useState(null);

	const handleOnEvent = (event: MouseEvent | KeyboardEvent) => {
		setActiveEvent(event.timeStamp);
	};

	useEffect(() => {
		if (isPrivateRouteState) {
			document.addEventListener('keypress', handleOnEvent, true);
			document.addEventListener('click', handleOnEvent, false);
		}

		return () => {
			document.removeEventListener('keypress', handleOnEvent, true);
			document.removeEventListener('click', handleOnEvent, false);
		};
	}, [
		isPrivateRouteState
	]);

	const documentVisible = useVisibilityChange();

	useThrottledEffect(() => {
		if (isPrivateRouteState) getUserAuth();
	}, [
		documentVisible,
		activeEvent
	], APP_CONF_VARS.timeout.reload_session);

	const getUserFiltered = (userState: GlobalUser) => {

		// user keys list to not send to GTM
		const userFilter = [
			'permissions',
		];

		// user data filtered without keys
		const userFiltered = Object.fromEntries(
			Object.entries(userState).filter(([
				key
			]) => !userFilter.includes(key))
		);

		return userFiltered;
	};

	const userFiltered = getUserFiltered(user) as userFilteredProps;
	let filters = [
		'lpdipro'
	];

	// ENV
	const filterEnv = APP_CONF_VARS.env === 'production' ? 'env_production' : 'env_development';
	filters.push(filterEnv);

	// ZONES
	if (userFiltered.zones?.length) filters = [
		...filters,
		...userFiltered.zones
	];

	// SEGMENT
	if (userFiltered.segment) filters.push(userFiltered.segment);
	if (APP_CONF_VARS.env === 'production' && userFiltered.segment) filters = filters.concat(userFiltered.segment);

	// BEAMER FILTERS
	if (userFiltered.beamer?.filter) filters = filters.concat(userFiltered.beamer.filter);

	const initialDataLayer = {
		env: APP_CONF_VARS.env,
		beamer: {
			id: APP_CONF_VARS.tools.beamer.product_id,
			filter: filters.join('; ')
		}
	};

	const loadGtm = () => {
		// START GOOGLE TAG MANAGER
		const tagManagerArgs = {
			gtmId: APP_CONF_VARS?.tools?.google?.tag_manager?.key || '',
			dataLayer: initialDataLayer
		};
		TagManager.initialize(tagManagerArgs);
		// END GOOGLE TAG MANAGER
	};

	useEffect(() => {
		if (!isGTMLoadedState) {
			loadGtm();
			setGTMLoadedState(true);
		}
	}, [
		isGTMLoadedState
	]);

	const initializedDevice = new Device();
	const didLogRefAuth = useRef(false);

	// EFFECT ON APP LOAD
	useEffect(() => {
		function handleResize() {
			// Refresh device store
			initializedDevice.refresh();
			const payload = {
				display: initializedDevice.display,
				orientation: initializedDevice.orientation
			};
			dispatch(deviceUpdate(payload));
		}

		window.addEventListener('resize', handleResize);

		if (didLogRefAuth.current === false) {
			didLogRefAuth.current = true;
			// app is loading at the beginning of each app load
			const twigLoader = document.querySelector('.loader');
			if (twigLoader) twigLoader.remove();

			getSessionValid();

			// EVENT EMITTER SUBSCRIPTIONS
			const eventNames = Object.keys(EVENTS);
			eventNames.forEach(eventName => {
				eventEmitter.subscribe(eventName, () => null);
			});
			handleResize();
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [
	]);

	// EFFECT ONG LOCATION CHANGE
	useEffect(() => {
		// START CUSTOM EVENT
		setIsPrivateRouteState(matchRoutes(PRIVATE_ROUTES_GLOBAL, location.pathname)?.length ? true : false);
		const data = {
			location: {
				...window.location,
				...location
			},
			company: {
				id: 0,
				name: 'unknown',
				...user.company
			},
			user: getUserFiltered(user),
			...initialDataLayer,
		};

		// Event custom dispatched to be used with GTM
		eventEmitter.dispatch(EVENTS.LOCATION_CHANGED, data);

		// END CUSTOM EVENT

		// We check if the user session  exists after XXX seconds
		const lifetimeInMilliseconds = APP_CONF_VARS.session.lifetime + 1000;
		const interval = setInterval(() => {
			if (user.authenticated) {
				const getUserAuthCallback = (responseParsed: responseParsedProps) => {
					if (!responseParsed.payload?.user) {
						// the setInterval must be cleared if called only once
						clearInterval(interval);
					}
				};
				// app is loading at the beginning of each /me calls
				getUserAuth(getUserAuthCallback);
			}
		}, lifetimeInMilliseconds);
	}, [
		location
	]);

	// EFFECT ON USER.AUTHENTICATED STATE CHANGE
	useEffect(() => {
		// data object to send to GTM
		const data = {
			...initialDataLayer,
			company: user.authenticated ? user.company : {
				id: 0,
				name: '',
			},
			user: user.authenticated ? getUserFiltered(user) : {
			},
		};
		// send data to GTM
		eventEmitter.dispatch(user.authenticated ? EVENTS.USER_LOGGED_IN : EVENTS.USER_LOGGED_OUT, data);
	}, [
		user.authenticated
	]);

	let appVersion = (
		<Loader
			message={t('general.loading')}
		/>
	);
	if (appIsLoaded && user.authenticated) {
		appVersion = <AppPrivate />;
	} else if (appIsLoaded) {
		appVersion = <AppPublic />;
	}
	return (
		<div className={`${styles.App} ${isDebug && APP_CONF_VARS.env !== 'production' ? 'debug' : ''}`} >
			{toastMsgsElement}
			{appVersion}
		</div>
	);
}

export default withTranslation()(LpdiProApp);
