import * as React from 'react';
import {
	useState,
} from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
	useParams,
} from 'react-router-dom';

// LAYOUTS
import CompanyReport from '@layouts/Pages/PageDemand/report/company';
import InternalInformationReport from '@layouts/Pages/PageDemand/report/internal-information';
import SearchCriteriasReport from '@layouts/Pages/PageDemand/report/search-criterias';

// STYLES
import styles from './report.module.scss';

// TYPES
import {
	Demand,
	DemandJsonPrivate,
	PageDemandOutletContextType,
} from '@@types/Demand';
import Modal, {
	ModalSize,
} from '@components/modal';
import ModalUpdateDemandState from '@layouts/Modals/ModalUpdateDemandState';

function Report() {
	const {
		demandGlobalState,
		demandIsLoaded,
		isDesktopResolution,
	}: PageDemandOutletContextType = useOutletContext();

	const { demandid } = useParams();
	const currentDemandId = !isNaN(Number(demandid)) ? Number(demandid) : null;

	const editUrlCompany = `${APP_CONF_VARS.sfPath}/Demands/${currentDemandId}/edition`;
	const editUrlSearchCriterias = `${APP_CONF_VARS.sfLegacyPath}/offer/searchDemande/editdemand/${currentDemandId}`;
	const demand = demandIsLoaded ? new Demand(demandGlobalState) : null;

	const hiddenStateAction = [
		'abandoned',
		'failed',
		'successful',
	];

	const canEditDemand = !hiddenStateAction.includes(demandGlobalState?.state);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const modalElement = demandIsLoaded ? (
		<Modal
			hasHeader={false}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenModal}
			size={ModalSize.DEFAULT}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<ModalUpdateDemandState
				demand={demandGlobalState as DemandJsonPrivate}
				isDesktopResolution={isDesktopResolution}
				setIsOpenModal={setIsOpenModal}
			/>
		</Modal>
	) : null;

	return demandIsLoaded ? (
		<div className={styles.report} >
			<div className={`${styles.section} ${styles.section__left}`}>
				<CompanyReport
					editUrl={canEditDemand ? editUrlCompany : null}
					establishmentId={demandGlobalState?.recipient?.company?.establishment_id}
					recipient={demand?.recipient}
				/>
			</div>
			<div className={`${styles.section} ${styles.section__right}`}>
				<InternalInformationReport
					canEdit={canEditDemand}
					demandGlobalState={demandGlobalState as DemandJsonPrivate}
					setIsOpenModal={setIsOpenModal}
				/>
				<SearchCriteriasReport
					editUrl={canEditDemand ? editUrlSearchCriterias : null}
					searchCriterias={demand?.search_criterias}
				/>
			</div>
			{modalElement}
		</div>
	) : null;
}

export default withTranslation()(Report);
