import * as React from 'react';

import {
	ChangeEvent,
	createElement,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import ComponentWithRadioButton, {
	ComponentWithRadioButtonProps,
} from '@components/form/input-radio-group/component-with-radio-button';
import InputRadio, {
	InputRadioProps,
} from '@components/form/input-radio-group/input-radio';
import {
	InputProps,
} from '@components/form/input';

// ENUMS
import {
	EnumInputPosition,
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// STYLING
import styles from './input-radio-group.module.scss';

interface InputRadioGroupProps extends Omit<InputProps, 'hasBorder' | 'hasShadow'> {
	options: ComponentWithRadioButtonProps[];
	selectedIndex?: number;
}

const InputRadioGroup = ({
	className,
	customError,
	'data-testid': dataTestid,
	disabled = false,
	inputPosition = EnumInputPosition.LEFT,
	invalid,
	onChange,
	options,
	methods,
	name,
	required,
	selectedIndex = 0,
	validationType,
}: InputRadioGroupProps): JSX.Element => {

	const { t } = useTranslation();
	const isControled = methods?.register;
	const registerRules: InputProps['registerRules'] = {
	};

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.radio')
			};
		}
	}

	const error = invalid ? {
		message: customError || /* istanbul ignore next */ methods?.formState?.errors[name]
	} : {
	};

	const classes = [
		styles.input_radio_group,
		styles[`position__${inputPosition}`]
	];

	if (className) classes.push(className);
	if (error?.message || invalid) classes.push('invalid');

	const initialState: InputRadioGroupProps = {
		customError: customError,
		disabled: disabled,
		error: invalid ? {
			message: customError || /* istanbul ignore next */ methods?.formState?.errors[name]
		} : {
		},
		invalid: invalid,
		name,
		options: options,
		selectedIndex: selectedIndex,
		type: EnumInputType.CHECKBOX,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const InputHtml = event.target;
		let currentIndex = state.selectedIndex;

		options.forEach((item: InputRadioProps, index) => {
			if (item.id === InputHtml.id && item.disabled !== true && !state.disabled) {
				currentIndex = index;
			}
		});

		const newState: InputRadioGroupProps = {
			...state,
			options: options,
			selectedIndex: currentIndex,
		};
		setState(newState);

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.clearErrors(newState.name);
		}

		/* istanbul ignore next */
		if (onChange) onChange(event, newState);
	}

	const errorMsgElement = error?.message ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{error.message}
		</div>
	) : null;

	function formatFields(fieldsToFormat: InputRadioGroupProps['options']) {
		const formatedFields = fieldsToFormat.map((field: ComponentWithRadioButtonProps, index: number) => {
			let newComponent = null;
			if (field.componentType) {
				newComponent = createElement(ComponentWithRadioButton, {
					...{
						...field,
						checked: state.selectedIndex !== null && state.selectedIndex === index,
						disabled: state.disabled || field.disabled,
						'data-testid': `${dataTestid}-radio-group`,
						key: index,
						onChange: handleOnChange,
						displayName: field.componentType
					},
				});
			} else {
				newComponent = createElement(InputRadio, {
					...{
						...field,
						checked: state.selectedIndex !== null && state.selectedIndex === index,
						'data-testid': `${dataTestid}-radio-group`,
						disabled: state.disabled || field.disabled,
						key: index,
						onChange: handleOnChange
					},
				});
			}
			return newComponent;
		});
		return formatedFields.filter((field: object) => field !== undefined);
	}

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{formatFields(options)}
			{error?.message || invalid ? errorMsgElement : null}
		</div>
	);
};

InputRadioGroup.displayName = EnumComponentType.INPUT_RADIO_GROUP;

export {
	InputRadioGroup as default,
	InputRadioGroupProps,
};
